<mat-icon class="iblack" svgIcon="ladestation"></mat-icon>
<div class="all">
    <div class="info">
        <span class="name">{{evse.name}}</span> <mat-hint>{{getPwr().toFixed(1)}}kW / {{getMaxPwr().toFixed(1)}}kW</mat-hint>
        <mat-progress-bar color="warn" mode="determinate" [value]="getPwr() / getMaxPwr() * 100"></mat-progress-bar>
    </div>

    <div class="evse-buttons">
        <button [disabled]="!evse.canEdit" mat-icon-button (click)="handleEditEvse(evse)"><mat-icon>edit</mat-icon></button>
        <button [disabled]="!evse.canDelete" mat-icon-button (click)="handleDeleteEvse(evse)"><mat-icon>delete</mat-icon></button>
    </div>
</div>
