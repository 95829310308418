<div class="login-wrapper d-flex justify-content-center align-items-center">
  <mat-card appearance="outlined" class="box mat-card">
    <mat-card-header>
      <mat-card-title><h1>IO-ELON</h1></mat-card-title>
    </mat-card-header>

      <form class="login-form" *ngIf="token===undefined" [formGroup]="requestForm" (ngSubmit)="onSubmitRequest()">
        <mat-card-content>
          Bitte geben sie die mit ihrem Account verknüpfte E-Mail an.
          <br>
          <br>
        <mat-form-field class="full-width-input">
          <input type="email" name="email" matInput placeholder="Email" formControlName="email" required email>
          <mat-error *ngIf="isFieldInvalid('email')">
            Bitte geben Sie ihre E-Mail Adresse ein.
          </mat-error>
        </mat-form-field>
        <button [disabled]="loading" id="btn-login" mat-stroked-button class="bg-blue-1 btn-block" type="submit">Passwort zurück setzen</button>
        </mat-card-content>
      </form>
      <form class="login-form" *ngIf="token!==undefined" [formGroup]="resetForm" (ngSubmit)="onSubmitReset()">
        <mat-card-content>
         <mat-form-field class="full-width-input">
          <input matInput name="password" type="password" placeholder="Passwort" formControlName="password" required>
          <mat-error *ngIf="isFieldInvalid('password')">
            Bitte geben Sie Ihr neues Passwort ein.
          </mat-error>
         </mat-form-field>
         <mat-form-field class="full-width-input">
          <input matInput name="password2" type="password" placeholder="Passwort wiederholen" formControlName="password2" required>
          <mat-error *ngIf="isFieldInvalid('password2')">
            Bitte geben Sie Ihr neues Passwort erneut ein.
          </mat-error>
        </mat-form-field>
        <mat-error *ngIf="passwordsDiffer()">
            Die eingegebenden Passwörter unterscheiden sich.
        </mat-error>
        <button [disabled]="loading" id="btn-login" mat-stroked-button class="bg-blue-1 btn-block" type="submit">Passwortänderunng speichern</button>
        </mat-card-content>
      </form>
  </mat-card>
</div>
