<mat-icon class="sun-icon">call_split</mat-icon>
<div class="all">
    <span class="name">{{load.name}}</span><div *ngIf="isMeterBased();then meterIcon"></div>
    <mat-hint matTooltip="Aktuelle Gebäudelast/Maximal erwarteter Peak für die nächsten 24 Stunden">{{getPwr().toFixed(1)}}kW / {{getMaxPwr().toFixed(1)}}kW</mat-hint>
    <mat-progress-bar color="warn" mode="determinate" [value]="getPwr() / getMaxPwr() * 100"></mat-progress-bar>
    <div class="load-buttons">
        <button [disabled]="!load.canEdit" mat-icon-button (click)="handleEditLoad(load)"><mat-icon>edit</mat-icon></button>
        <button [disabled]="!load.canDelete" mat-icon-button (click)="handleDeleteLoad(load)"><mat-icon>delete</mat-icon></button>
    </div>
</div>
<ng-template #meterIcon>
    <mat-icon class="sun-icon">monitor_heart</mat-icon>
</ng-template>
