import {Component, Inject, OnInit} from '@angular/core';
import {ChargingSession} from '@io-elon-common/frontend-api';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss']
})
export class DetailsDialogComponent implements OnInit {

  constructor(
      public readonly dialogRef: MatDialogRef<DetailsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public readonly data: ChargingSession
  ) { }

  ngOnInit(): void {
  }

}
