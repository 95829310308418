import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {Box, Meter, SystemInfoMeterType} from '@io-elon-common/frontend-api';
import {MeterService} from 'src/app/modules/meter/service/meter.service';
import {SystemService} from "../../../../services/api-handlers/system.service";
import {AuthService} from "../../../../shared/guards/auth.service";
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-meter-details-view',
    templateUrl: './meter-details-view.component.html',
    styleUrls: ['./meter-details-view.component.scss']
})
export class MeterDetailsViewComponent implements OnInit, OnDestroy {
    private meterSubscription!: Subscription;
    private routerEventSubscription!: Subscription;
    private currentMeterId: number | undefined = undefined;

    public boxes: Box[] = [
        {
            description: 'Name',
            icon: 'desktop_mac-black',
            percentage: 33,
            value: '⏳...'
        }, {
            description: 'Typ',
            icon: 'settings',
            percentage: 33,
            value: '⏳...'
        }, {
            description: 'Adresse',
            icon: 'search',
            percentage: 33,
            value: '⏳...'
        }, {
            description: 'Letzter Kontakt',
            icon: 'clock',
            percentage: 33,
            value: '⏳...'
        }
    ];


    public meter!: BehaviorSubject<Meter | undefined>;
    public meterInfo?: SystemInfoMeterType;


    public constructor(
        private readonly meterService: MeterService,
        private readonly route: ActivatedRoute,
        private readonly systemService: SystemService,
        public readonly authService: AuthService,
        private readonly datePipe: DatePipe,
        private readonly router: Router
    ) {
    }

    public ngOnInit(): void {
        this.routerEventSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.currentMeterId !== undefined) {
                    const id = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
                    if (this.currentMeterId !== id) {
                        this.meterSubscription.unsubscribe();
                        this.init();
                    }
                }
            }
        });

        this.init();
    }

    private async update(meter: Meter): Promise<void> {
        let lastContact = this.datePipe.transform(new Date(meter.lastMsg), 'dd.MM.yyyy, HH:mm:ss');
        if (meter.lastMsg === 0 || lastContact === null) {
            lastContact = "Unbekannt";
        }

        this.boxes[0].value = meter.name || '---';
        this.boxes[1].value = meter.type;
        this.boxes[2].value = meter.hostname + ':' + meter.port + (meter.modbusId ? ' - ' + meter.modbusId : '');
        this.boxes[3].value = lastContact;
        this.meterInfo = (await this.systemService.getSystemInfo()).supportedMeters.find(x => x.key === this.meter.value?.type);
        if (this.meterInfo?.type === "API") {
            this.boxes[2].value = "/api/smartmeter/v1/"+meter.id;
        }
    }

    public ngOnDestroy(): void {
        this.routerEventSubscription.unsubscribe();
    }

    public async handleApiDataV1Click(): Promise<void> {
        let meter = this.meter.value;
        if (meter) {
            await this.meterService.showApiDataV1Dialog(meter.id);
        }
    }

    validate(): string[] {
        return [];
    }

    private init(): void {
        this.currentMeterId = parseInt(this.route.snapshot.paramMap.get('id') as string, 10);
        this.meter = this.meterService.get(this.currentMeterId);
        this.meterSubscription = this.meter.subscribe(meter => {
            if (meter) {
                this.update(meter);
            }
        });
    }
}
