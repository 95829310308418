import {Pipe, PipeTransform} from '@angular/core';
import {PlugState, PlugStateIcon} from './plug-state.enum';

@Pipe({
    name: 'plugIcon'
})
export class PlugIconPipe implements PipeTransform {

    public constructor() {
    }

    transform(plugState: PlugState): string {
        return PlugStateIcon[plugState];
    }
}
