import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {NotificationEntry, OcppUplink} from '@io-elon-common/frontend-api';
import {MatTableDataSource} from '@angular/material/table';
import {OcppUplinkService} from '../../service/ocpp-uplink.service';
import {AbstractTableComponent} from '../../../../shared/components/tables/AbstractTableComponent';
import {MatPaginator} from '@angular/material/paginator';
import {SystemService} from '../../../../services/api-handlers/system.service';
import {EvseService} from '../../../evse/service/evse.service';
import {ToastrService} from 'ngx-toastr';
import {NotificationService} from '../../../../services/api-handlers/notification.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-ocpp-uplinks-table',
    templateUrl: './ocpp-uplinks-table.component.html',
    styleUrls: ['./ocpp-uplinks-table.component.scss']
})
export class OcppUplinksTableComponent extends AbstractTableComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
    @Input()
    public ocppUplinks!: OcppUplink[];
    public passwords: Array<string | null> = [];
    public activeNotifications: NotificationEntry[] = [];

    displayedColumns: string[] = ['id', 'name', 'baseUrl', 'cpi', 'userName', 'pass', 'ssl', 'defToken', 'evses', 'actions'];
    dataSource = new MatTableDataSource<OcppUplink>([]);
    expandedOcppUplink: Set<number> = new Set();
    private notificationSubscription?: Subscription;
    constructor(
        private readonly ocppUplinkService: OcppUplinkService,
        private readonly systemService: SystemService,
        private readonly evseService: EvseService,
        private readonly notificationService: NotificationService,
        private readonly toastr: ToastrService
    ) {
        super();
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.ocppUplinks);
        this.dataSource.paginator = this.paginator;
        this.notificationSubscription = this.notificationService.getActiveNotifications().subscribe(x => {
            if (x == undefined)
                return;
            this.activeNotifications = x.activeNotifications.filter(n => n.key === "uplink.alert");
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = this.ocppUplinks;
    }

    public async edit(ocppUplink: OcppUplink): Promise<void> {
        ocppUplink.pass = await this.ocppUplinkService.getPassword(ocppUplink.id);
        await this.ocppUplinkService.showEditDialog(ocppUplink);
        delete this.passwords[ocppUplink.id];
    }

    public async delete(ocppUplink: OcppUplink): Promise<void> {
        await this.ocppUplinkService.showDeleteDialog(ocppUplink, {});
    }

    public getPw(ocppUplink: OcppUplink): string | null | undefined {
        return this.passwords[ocppUplink.id];
    }

    public async loadPw(ocppUplink: OcppUplink): Promise<void> {
        this.passwords[ocppUplink.id] = null;
        try {
            this.passwords[ocppUplink.id] = await this.ocppUplinkService.getPassword(ocppUplink.id);
        } catch (e) {
            delete this.passwords[ocppUplink.id];
        }

    }
    public isDeletable(ocppUplink: OcppUplink): boolean {
        return ocppUplink.canDelete && (ocppUplink.connectedEvse === undefined || ocppUplink.connectedEvse?.length === 0)
    }

    public getExtraDeleteTooltipText(ocppUplink: OcppUplink): string {
        return (ocppUplink.connectedEvse === undefined || ocppUplink.connectedEvse.length === 0) ? ''
            : ' nicht möglich (' + this.getNumberOfEvseConnectedText(ocppUplink) + ')';
    }

    public getNumberOfEvseConnectedText(ocppUplink: OcppUplink) {
        if (ocppUplink.connectedEvse === undefined) {
            return '';
        }
        return '' + ocppUplink.connectedEvse.length + (ocppUplink.connectedEvse.length === 1 ? ' Ladepunkt' : ' Ladepunkte') + ' angeschlossen';
    }

    public expandableOpen(id: number) {
        this.expandedOcppUplink.add(id);
    }

    public expandableClose(id: number) {
       this.expandedOcppUplink.delete(id);
    }
    public isExpandableOpened(id: number) {
        return this.expandedOcppUplink.has(id);
    }

    public async disconnectConnection(evseId: number) {
        const evse = await this.evseService.getPromise(evseId);
        const evseTypes = (await this.systemService.getSystemInfo()).supportedEvses.find(e => e.key === evse.typeKey);
        const isActionSupported = !!evseTypes?.actors.find(sai => sai.key === evse.actorKey)?.supportedActions.find(sa => sa.name === "CloseOcpp");
        if (isActionSupported) {
            const result = await this.evseService.executeAction(evseId, {name: "CloseOcpp", arguments: []});
            if (result.success) {
                this.toastr.success(result.value);
            } else {
                this.toastr.warning(result.value);
            }
        } else {
            this.toastr.warning("Aktion nicht möglich");
        }
    }

    public getEvseLinkClass(evseId: number): string {
        return this.activeNotifications.filter(n => n.evse === evseId).length > 0 ? 'evse-link-with-alert' : 'evse-link';

    }

    public getEvseAlertCount(ocppUplink: OcppUplink): number {
        let count = 0;
        ocppUplink.connectedEvse?.forEach(evse => {
            count += this.activeNotifications.filter(n => n.evse == evse.id).length;
        });
        return count;
    }

    public getUplinkNotificationText(ocppUplink: OcppUplink): string {
        let text = '';
        ocppUplink.connectedEvse?.forEach(evse => {
            this.activeNotifications.filter(n => n.evse == evse.id).forEach(notification => text = text + evse.name + ": " + notification.message + "\n");
        });
        return text;
    }

    public trackById(idx: number, item:{id: number}) {
        return item.id;
    }

    removePw(ocppUplink: OcppUplink): void {
        delete this.passwords[ocppUplink.id];
    }

    ngOnDestroy(): void {
        this.notificationSubscription?.unsubscribe()
    }
}
