<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <!-- There is inline padding applied to this node using styles.
      This padding value depends on the mat-icon-button width. -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <app-power-supply-tree-view-node *ngIf="node.ps"
                                         [ps]="getPowerSupply(node.ps)"
                                         [evses]="getEvseLeafs(getPowerSupply(node.ps))"
                                         [loads]="getLoadLeafs(getPowerSupply(node.ps))"
                                         [solarSystems]="getSolarLeafs(getPowerSupply(node.ps))"
                                         [basis]="basis">
        </app-power-supply-tree-view-node>
        <app-evse-tree-view-node *ngIf="node.evse" [evse]="getEvse(node.evse)" [basis]="basis"></app-evse-tree-view-node>
        <app-solar-system-tree-view-node *ngIf="node.solar" [solarSystem]="getSolarSystem(node.solar)" [powerSupplies]="powerSupplies"></app-solar-system-tree-view-node>
        <app-load-tree-view-node *ngIf="node.load" [load]="getLoad(node.load)" [powerSupplies]="powerSupplies"></app-load-tree-view-node>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.ps.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <app-power-supply-tree-view-node *ngIf="node.ps"
                                             [ps]="getPowerSupply(node.ps)"
                                             [evses]="getEvseLeafs(getPowerSupply(node.ps))"
                                             [loads]="getLoadLeafs(getPowerSupply(node.ps))"
                                             [solarSystems]="getSolarLeafs(getPowerSupply(node.ps))"
                                             [basis]="basis">
            </app-power-supply-tree-view-node>
            <app-evse-tree-view-node *ngIf="node.evse" [evse]="getEvse(node.evse)" [basis]="basis"></app-evse-tree-view-node>
            <app-solar-system-tree-view-node *ngIf="node.solar" [solarSystem]="getSolarSystem(node.solar)" [powerSupplies]="powerSupplies"></app-solar-system-tree-view-node>
            <app-load-tree-view-node *ngIf="node.load" [load]="getLoad(node.load)" [powerSupplies]="powerSupplies"></app-load-tree-view-node>
        </div>
        <!-- There is inline padding applied to this div using styles.
            This padding value depends on the mat-icon-button width.  -->
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
    </mat-nested-tree-node>
</mat-tree>
