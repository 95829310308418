export enum PlugState {
    PLUG_STATE_UNPLUGGED,
    PLUG_STATE_UNPLUGGED_ALW,
    PLUG_STATE_PAUSED,
    PLUG_STATE_PAUSED_EVSE,
    PLUG_STATE_PAUSED_VEHICLE,
    PLUG_STATE_CHARGING,
    PLUG_STATE_UNKNOWN,
    PLUG_STATE_ERROR
}

export const PlugStateLabel: { [key in PlugState]: string } = {
    [PlugState.PLUG_STATE_UNPLUGGED]: "Nicht eingesteckt",
    [PlugState.PLUG_STATE_PAUSED_EVSE]: "Pausiert durch IO-ELON",
    [PlugState.PLUG_STATE_PAUSED_VEHICLE]: "Beendet durch Fahrzeug",
    [PlugState.PLUG_STATE_PAUSED]: "Pausiert",
    [PlugState.PLUG_STATE_CHARGING]: "Laden",
    [PlugState.PLUG_STATE_UNPLUGGED_ALW]: "Nicht eingesteckt freigeschaltet",
    [PlugState.PLUG_STATE_ERROR]: "Fehlerhafter Steckerzustand",
    [PlugState.PLUG_STATE_UNKNOWN]: "Unbekannt",
};

export const PlugStateIcon: { [key in PlugState]: string } = {
    [PlugState.PLUG_STATE_UNPLUGGED]: "plug_disconnect_grey",
    [PlugState.PLUG_STATE_PAUSED_EVSE]: "plug_pause_grey",
    [PlugState.PLUG_STATE_PAUSED_VEHICLE]: "plug_pause_blue",
    [PlugState.PLUG_STATE_PAUSED]: "plug_connect_grey",
    [PlugState.PLUG_STATE_CHARGING]: "plug_energy_blue",
    [PlugState.PLUG_STATE_UNPLUGGED_ALW]: "plug_disconnect_blue",
    [PlugState.PLUG_STATE_ERROR]: "plug_disconnect_red",
    [PlugState.PLUG_STATE_UNKNOWN]: "plug_unknown_grey",
};
