import {Component, Input} from '@angular/core';
import {Basis, DataValueNum, Evse, Load, PowerSupply, SolarSystem} from '@io-elon-common/frontend-api';
import {ToastrService} from 'ngx-toastr';
import {PowerSupplyService} from '../../../service/power-supply.service';
import {num} from '../../../../../shared/helper/util-functions';
import {LoadUtilsService} from "../../../../../shared/helper/load-utils.service";

@Component({
    selector: 'app-power-supply-tree-view-node',
    templateUrl: './power-supply-tree-view-node.component.html',
    styleUrls: ['./power-supply-tree-view-node.component.scss']
})
export class PowerSupplyTreeViewNodeComponent {

    @Input()
    public ps!: PowerSupply;

    @Input()
    public evses!: Evse[];

    @Input()
    public loads!: Load[];

    @Input()
    public solarSystems!: SolarSystem[];

    @Input()
    public basis!: Basis;

    public constructor(
        private readonly toastr: ToastrService,
        private readonly powerSupplyService: PowerSupplyService,
        private readonly loadUtils: LoadUtilsService,
    ) {
    }

    public async handleEditPowerSupply(ps: PowerSupply): Promise<void> {
        if(!this.basis) {
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        await this.powerSupplyService.showEditDialog(ps, this.basis);
    }

    public async handleDeletePowerSupply(ps: PowerSupply): Promise<void> {
        await this.powerSupplyService.showDeleteDialog(ps, {});
    }

    public solarSystemsToPowerSupply(panels: SolarSystem[], powerSupply: PowerSupply): SolarSystem[] {
        return panels.filter(p => p.powerSupplyId === powerSupply.id);
    }

    private getP(pwr: DataValueNum | undefined, amps: DataValueNum | undefined, volt: DataValueNum | undefined): number {
        return num(pwr?.val, num(amps?.val, 0) * num(volt?.val, 235));
    }
    private evsePwr(evse: Evse): number {
        const live = evse.liveData;
        const pwr = this.getP(live.evseP1, live.evseI1, live.evseU1) +
            this.getP(live.evseP2, live.evseI2, live.evseU2) +
            this.getP(live.evseP3, live.evseI3, live.evseU3);
        return pwr / 1000;
    }

    private loadPwr(load: Load): number {
        return this.loadUtils.getCurrentPwr(load);
    }
    public getPwr(): number {
        return this.evses.map(evse=>this.evsePwr(evse))
            .concat(this.loads.map(load=>this.loadPwr((load))))
            .reduce((sum, p)=>sum+p, 0);
    }

    public getMaxPwr(): number {
        return this.ps.maxAmps * 235 / 1000 * 3;
    }
}
