<div class="dialog-head">
    <h2 class="mat-h2">Protokoll</h2>
    <mat-icon class="close-icon" (click)="close()">close</mat-icon>
    <h4><app-evse-link [evseId]="evseId"></app-evse-link></h4>
</div>

<div class="log-container">
    <div class="w-100">
        <pre id="scroll-pre" class="w-100">{{log}}</pre>
    </div>
    <div class="d-flex justify-content-end flex-row gap-3">
        <button mat-raised-button color="primary" type="submit" (click)="downloadLog()">
            Protokoll Herunterladen
        </button>
    </div>
</div>
