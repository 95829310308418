<div>
    <mat-icon>account_tree</mat-icon>
    <div  class="all">
        <div class="info">
            <span class="name">{{ps.name}}</span><span>{{getPwr().toFixed(1)}}kW / {{(ps.maxAmps * 235 / 1000 * 3).toFixed(0)}}kW</span>
            <mat-hint>3x{{ps.maxAmps}}A</mat-hint>
            <mat-progress-bar color="warn" mode="determinate" [value]="getPwr() / getMaxPwr() * 100"></mat-progress-bar>
        </div>
        <mat-hint class="oadr-info" *ngIf="ps.oadrGridConnectionId"> OpenADR Grid Connection ID: {{ps.oadrGridConnectionId}}</mat-hint>
        <div class="ps-buttons">
            <!-- Removed as quick-fix for 1433 -->
            <!--<button mat-button [matMenuTriggerFor]="peaksGraph" [matMenuTriggerData]="{ps: ps}"><mat-icon>filter</mat-icon></button>-->
            <button [disabled]="!ps.canEdit" mat-icon-button (click)="handleEditPowerSupply(ps)"><mat-icon>edit</mat-icon></button>
            <button [disabled]="!ps.canDelete"  mat-icon-button (click)="handleDeletePowerSupply(ps)"><mat-icon>delete</mat-icon></button>
        </div>
    </div>
</div>
<mat-menu #peaksGraph="matMenu">
    <ng-template matMenuContent let-ps="ps">
        {{ps.name}}
        <app-peak-history-graph [powerSupplyId]="ps.id"></app-peak-history-graph>
    </ng-template>
</mat-menu>
<ng-template #loading>
    <mat-spinner></mat-spinner>
    Loading...
</ng-template>
