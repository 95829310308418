import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    Input,
    OnChanges,
    OnInit, Output,
    ViewChild
} from '@angular/core';
import {ChargePlan, ChargePlanValue, ReservationInstance, TimeValueMapping} from '@io-elon-common/frontend-api';
import {repeat} from '../../../../shared/helper/util-functions';
import {ReservationService} from '../../../reservations/service/reservation.service';
import {VehicleService} from '../../../vehicle/service/vehicle.service';
import {EvseService} from '../../../evse/service/evse.service';
import {localStorageGet, localStorageSave} from "../../../../shared/helper/typed-local-storage";
import {AuthService} from "../../../../shared/guards/auth.service";

function overlaps(val: number, start: number, len: number) {
    return val >= start && val <= (start + len);
}


interface PwrData {
    pwr: number
    soc: number
    i1: number
    i2: number
    i3: number
    iMax: number
}

const EMPTY_PWR_DATA: PwrData = {
    pwr: 0,
    soc: 0,
    i1: 0,
    i2: 0,
    i3: 0,
    iMax: 0
}

type PwrSelection = keyof PwrData;

interface PluggedPhase {
    start: number
    end: number
    vehicle: number
    evse: number
}

class GraphDetail {
    constructor(
        private readonly x: number,
        private readonly y: number,
        private readonly w: number,
        private readonly h: number,
        public readonly msg: string
    ) {
    }
    public overlaps(x: number, y: number): boolean {
        return overlaps(x, this.x, this.w) && overlaps(y, this.y, this.h);
    }
}

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const SINGLE_ARROW_SIZE = 3;
const TWO_ARROWS_SIZE = 2*SINGLE_ARROW_SIZE;
const GRAPH_SPACING = 20;
const LEGEND_SPACE_X = 70;
const LEGEND_SPACE_Y = 25;

const SINGLE_VEHICLE_MIN_HEIGHT = 80;

const DEFAULT_HELP_TEXT = "Für Details mit der Maus auf ein Element zeigen.";
type TimeRangePreset = 'next24Hours' | 'today' | 'week' | 'tMonth' | 'tQuarter';
@Component({
    selector: 'app-charge-plan-new',
    templateUrl: './charge-plan-new.component.html',
    styleUrls: ['./charge-plan-new.component.scss']
})
export class ChargePlanNewComponent implements AfterViewInit, OnChanges, OnInit {

    @ViewChild('canvasRef')
    public canvas!: ElementRef<HTMLCanvasElement>;

    @Input() public plan!: ChargePlan;
    @Input() public selectedDateRange!: TimeRangePreset;
    @Output() dateChangeEvent = new EventEmitter<{startTime: number}>();

    public timeVisible: boolean = localStorageGet("CHARGE_PLAN_TIME_VISIBLE", "false") === "true";
    public messagesVisible: boolean = localStorageGet("CHARGE_PLAN_MESSAGES_VISIBLE", "false") === "true";

    public pwrSelection: PwrSelection = "pwr";
    public combined = true;
    private combineState = 1;
    public showPv = true;
    public pvInBackground = true;
    public mergePvState = 0;
    public showLoads = true;
    public loadInBackground = false;
    public showDetails = true;
    public mergeLoadState = 1;
    public showEvses = true;
    public showPeak = true;
    public showLimit = true;
    public canvasWidth = 1000;
    public canvasHeight = 500;

    private mouseX = 0;
    private mouseY = 0;
    private zoomXMin = 0;
    private zoomXMax = 1000;
    private zoomYMin = 0;
    private zoomYMax = 500;
    private dragX = 0;
    private dragY = 0;

    private highlightX = false;
    private highlightXStart = 0;
    private highlightXEnd = 0;
    private highlightY = false;
    private highlightYStart = 0;
    private highlightYEnd = 0;

    private context!: CanvasRenderingContext2D;

    public vehicles: Set<number> = new Set<number>();
    private evses: Set<number> = new Set<number>();

    private timeslots = 42;
    private globalMaxValue = 16;
    private globalMaxPvVal = 0;
    private globalMaxLoadVal = 0;

    private reservations: ReservationInstance[][] = [];
    private socs: number[][] = [];
    private planValuesByVehicle: ChargePlanValue[][] = [];
    private planValuesByEvse: ChargePlanValue[][] = [];
    private pluggedPhasesVehicles: PluggedPhase[][] = [];
    private vehicleNames: string[] = [];
    private evseNames: string[] = [];
    private pvBySlot: PwrData[] = [];
    private loadBySlot: PwrData[] = [];
    private peakBySlot: PwrData[] = [];
    private fuseBySlot: PwrData[] = [];
    private pixPerT = 0;
    private pixPerMillisecond = 0;

    private reservationPatter!: CanvasPattern;
    private solarPatter!: CanvasPattern;
    private loadsPatter!: CanvasPattern;
    private graphDetails: GraphDetail[] = [];

    public info = DEFAULT_HELP_TEXT;

    public helpBlink = false;
    public helpBlinkTimeout: any;

    public isDev: boolean;

    public animationEnabled: boolean = true;

    private drawRequested = false;

    private numberOfDays = 1;

    public dateRange: Array<{ value: TimeRangePreset, name: string }> = [
        {value: 'next24Hours', name: 'Keine'},
        {value: 'today', name: 'Heute'},
        {value: 'week', name: 'letzte 7 Tage'}
    ];

    public constructor(
        private readonly reservationService: ReservationService,
        private readonly vehicleService: VehicleService,
        private readonly evseService: EvseService,
        private readonly ref: ElementRef,
        public readonly authService: AuthService
    ) {
        this.isDev = this.authService.isDeveloper();
        if (this.isDev) {
            this.dateRange.push(
                {value: 'tMonth', name: 'Diesen Monat (dev only)'},
                {value: 'tQuarter', name: 'Dieses Quartal (dev only)'});

        }

        this.vehicleService.getAllPromise().then(vehs => {
            for(const v of vehs) {
                this.vehicleNames[v.id] = v.name || "Fahrzeug " + v.id;
            }
        });
        this.evseService.getAllPromise().then(evses => {
            for(const e of evses) {
                this.evseNames[e.id] = e.name || "EVSE " + e.id;
            }
        });
    }


    private static getFromMatrix<T>(idx1: number, idx2: number, matrix: Array<Array<T>>): T | undefined {
        if(matrix[idx1] === undefined) {
            return undefined;
        }
        return matrix[idx1][idx2];
    }

    public wheelEvent(event: WheelEvent) {
        if (!event.ctrlKey) {
            return;
        }

        const x = event.offsetX;
        const y = event.offsetY;

        const w = this.canvasWidth;
        const h = this.canvasHeight;

        const zoomDir = -Math.sign(event.deltaY);
        const deltaBase = 10;
        let zoomXMinNew = this.zoomXMin + zoomDir * deltaBase *    (x / w)
        let zoomXMaxNew = this.zoomXMax - zoomDir * deltaBase * (1-(x / w))
        let zoomYMinNew = this.zoomYMin + zoomDir * deltaBase *    (y / h)
        let zoomYMaxNew = this.zoomYMax - zoomDir * deltaBase * (1-(y / h))

        if(zoomXMinNew < 0) zoomXMinNew = 0;
        if(zoomYMinNew < 0) zoomYMinNew = 0;
        if(zoomXMaxNew > w) zoomXMaxNew = w;
        if(zoomYMaxNew > h) zoomYMaxNew = h;

        if(zoomXMinNew > zoomXMaxNew - 5) {
            zoomXMinNew = this.zoomXMin
            zoomXMaxNew = this.zoomXMax
        }
        if(zoomYMinNew > zoomYMaxNew - 5) {
            zoomYMinNew = this.zoomYMin
            zoomYMaxNew = this.zoomYMax
        }

        this.zoomXMin = zoomXMinNew;
        this.zoomXMax = zoomXMaxNew;
        this.zoomYMin = zoomYMinNew;
        this.zoomYMax = zoomYMaxNew;
        this.draw("WheelEvent")

        event.preventDefault();
    }

    public mouseDown(event: MouseEvent) {
        this.dragX = event.offsetX;
        this.dragY = event.offsetY;
        event.preventDefault();
    }

    public mouseUp(event: MouseEvent) {
        if(this.highlightX) {
            const widthFactor = this.calcW(1);
            this.zoomXMax = this.zoomXMin + this.highlightXEnd / widthFactor;
            this.zoomXMin = this.zoomXMin + this.highlightXStart / widthFactor;
        }
        if(this.highlightY) {
            const heightFactor = this.calcH(1);
            this.zoomYMax = this.zoomYMin + this.highlightYEnd / heightFactor;
            this.zoomYMin = this.zoomYMin + this.highlightYStart / heightFactor;
        }
        this.highlightX = false;
        this.highlightY = false;
        this.draw("MouseUp");
        event.preventDefault();
    }

    public mouseDrag(event: MouseEvent) {
        const x = event.offsetX;
        const y = event.offsetY;

        const dx = (this.dragX - x) / this.calcW(1);
        const dy = (this.dragY - y) / this.calcH(1);

        if(event.buttons === 1 && !event.ctrlKey) {
            // Move
            if(!(this.zoomXMin + dx < 0) && !(this.zoomXMax + dx > this.canvasWidth)) {
                this.zoomXMin += dx;
                this.zoomXMax += dx;
            }
            if(!(this.zoomYMin + dy < 0) && !(this.zoomYMax + dy > this.canvasHeight)) {
                this.zoomYMin += dy;
                this.zoomYMax += dy;
            }
            this.dragX = x;
            this.dragY = y;
            this.draw("MouseDrag");
        } else if(event.buttons === 2 || (event.buttons === 1 && event.ctrlKey)) {
            this.highlightX = Math.abs(x - this.dragX) > 25;
            this.highlightY = Math.abs(y - this.dragY) > 25;
            if (this.highlightX) {
                this.highlightXStart = Math.min(this.dragX, x);
                this.highlightXEnd = Math.max(this.dragX, x);
            }
            if (this.highlightY) {
                this.highlightYStart = Math.min(this.dragY, y);
                this.highlightYEnd = Math.max(this.dragY, y);
            }
            this.draw("MouseDrag");
        }
    }

    public resetZoom(event?: MouseEvent) {
        this.zoomXMin = 0;
        this.zoomYMin = 0;
        this.zoomXMax = this.canvasWidth;
        this.zoomYMax = this.canvasHeight;
        this.draw("ResetZoom");
        event?.preventDefault();
    }

    private calcX(x: number): number {
        const w = this.canvasWidth;
        const w1 = this.zoomXMax - this.zoomXMin;
        const wFactor = w / w1;

        return (x - this.zoomXMin) * wFactor;
    }

    private calcXReverse(xScreen: number): number {
        const w = this.canvasWidth;
        const w1 = this.zoomXMax - this.zoomXMin;
        const wFactor = w / w1;

        return xScreen / wFactor + this.zoomXMin;
    }

    private calcY(y: number): number {
        const h = this.canvasHeight;
        const h1 = this.zoomYMax - this.zoomYMin;
        const hFactor = h / h1;

        return (y - this.zoomYMin) * hFactor;
    }

    private calcYReverse(yScreen: number): number {
        const h = this.canvasHeight;
        const h1 = this.zoomYMax - this.zoomYMin;
        const hFactor = h / h1;

        return yScreen / hFactor + this.zoomYMin;
    }

    private calcW(w: number): number {
        const w0 = this.canvasWidth;
        const w1 = this.zoomXMax - this.zoomXMin;
        const wFactor = w0 / w1;

        return w * wFactor;
    }

    private calcH(h: number): number {
        const h0 = this.canvasHeight;
        const h1 = this.zoomYMax - this.zoomYMin;
        const hFactor = h0 / h1;

        return h * hFactor;
    }

    ngOnInit(): void {
        this.canvasWidth = this.ref.nativeElement.offsetWidth;
    }

    public ngOnChanges() {
        if(!this.canvas) {
            // Never run before init
            return;
        }
        if (!this.selectedDateRange) {
            this.selectedDateRange = "next24Hours";
        }
        this.vehicles.clear();
        const currentDate = new Date();
        currentDate.setUTCHours(23,59,59,999);
        this.numberOfDays = Math.floor((currentDate.getTime() - this.calculateStartDate().getTime()) / 1000 / 60 / 60 / 24) + 1;
        this.plan.evseVehicleMapping?.map(m => m.vehicle).filter(id => id != null).forEach(id => this.vehicles.add(id));
        this.plan.planValues.map(pv => pv.vehicle).filter(id => id != null).forEach(id => this.vehicles.add(id as number));
        this.evses.clear();
        this.plan.evseVehicleMapping?.map(m => m.evse).filter(id => id != null).forEach(id => this.evses.add(id));
        this.plan.planValues.map(pv => pv.evse).filter(id => id != null).forEach(id => this.evses.add(id as number));

        const animationSetting = localStorageGet("CHARGE_PLAN_ANIMATION_VEHICLE_LIMIT", "20")
        switch (animationSetting) {
            case "off":
                this.animationEnabled = false;
                break;
            case "on":
                this.animationEnabled = true;
                break;
            case "5":
                this.animationEnabled = this.vehicles.size <= 5;
                break;
            case "10":
                this.animationEnabled = this.vehicles.size <= 10;
                break;
            case "20":
                this.animationEnabled = this.vehicles.size <= 20;
                break;
            case "30":
                this.animationEnabled = this.vehicles.size <= 30;
                break;
            default:
                console.error("Unknown Animation setting, default to off")
                this.animationEnabled = false;
        }

        this.socs = [];
        this.planValuesByVehicle = [];
        this.planValuesByEvse = [];

        this.timeslots = this.plan.limit.length
        this.pixPerT = (this.canvasWidth - LEGEND_SPACE_X - TWO_ARROWS_SIZE) / this.timeslots;
        this.pixPerMillisecond = (this.canvasWidth - LEGEND_SPACE_X - TWO_ARROWS_SIZE) / (DAY * this.numberOfDays);

        for(let t = 0; t < this.timeslots; t++) {
            const time = this.slotToTime(t);
            const overlappingPlaneValueByVehicle = this.plan.planValues.filter(pv => overlaps(time, pv.t, 5 * MINUTE));
            const overlappingSoc = this.plan.socPredictions.filter(soc => overlaps(time, soc.t, 5 * MINUTE));

            overlappingPlaneValueByVehicle.forEach(pv => {
                    if(pv.vehicle !== undefined) {
                        if(!this.planValuesByVehicle[pv.vehicle]) {
                            this.planValuesByVehicle[pv.vehicle] =  [];
                        }
                        this.planValuesByVehicle[pv.vehicle][t] = pv;
                    }
                    if(pv.evse !== undefined) {
                        if(!this.planValuesByEvse[pv.evse]) {
                            this.planValuesByEvse[pv.evse] = [];
                        }
                        this.planValuesByEvse[pv.evse][t] = pv;
                    }
                }
            );

            overlappingSoc.forEach(soc => {
                if(!this.socs[soc.vehicle]) {
                    this.socs[soc.vehicle] = [];
                }
                this.socs[soc.vehicle][t] = soc.soc;
            });

            this.pvBySlot[t] = this.getValFromMapping(time, this.plan.pv);
            this.loadBySlot[t] = this.getValFromMapping(time, this.plan.loads);
            this.peakBySlot[t] = this.getValFromMapping(time, this.plan.peaks);
            this.fuseBySlot[t] = this.getValFromMapping(time, this.plan.limit);
        }

        this.pluggedPhasesVehicles = [];
        for(const v of this.vehicles) {
            this.pluggedPhasesVehicles[v] = [];
            const pvs = this.planValuesByVehicle[v] || [];
            let evse: number | undefined = undefined;
            let currentPhase: PluggedPhase | null = null;
            for(let t = 0; t < this.timeslots; t++) {
                const newEvse = pvs[t] && pvs[t].evse;
                if(newEvse !== evse) {
                    if(currentPhase) {
                        currentPhase.end = t;
                        this.pluggedPhasesVehicles[v].push(currentPhase);
                        currentPhase = null;
                    }
                    if(newEvse !== undefined) {
                        currentPhase = {
                            evse: newEvse,
                            vehicle: v,
                            start: t,
                            end: t,
                        }
                    }
                }
                evse = newEvse;
            }
            if(currentPhase) {
                currentPhase.end = this.timeslots - 1;
                this.pluggedPhasesVehicles[v].push(currentPhase);
            }
        }

        this.updateUnit();

        let cnt = 0;
        this.vehicles.forEach(async v => {
            this.reservations[v] =
                (await this.vehicleService.getReservationsByTimePromise(v, this.plan.initTime, this.plan.initTime + DAY)) || [];
            cnt++;
            if(cnt === this.vehicles.size - 1) {
                this.draw("ngOnChangeAsyncReservationUpdate");
            }
        });

        if (this.context != null) {
            this.draw("ngOnChange");
        }
    }

    private getValFromMapping(time: number, mappings: TimeValueMapping[]) {
        let before: TimeValueMapping | undefined;
        for(const m of mappings) {
            if(m.time <= time && (!before || m.time > before.time)) {
                before = m;
            }
        }

        let after: TimeValueMapping | undefined;
        for (const m of mappings) {
            if (m.time >= time && (!after || m.time < after.time)) {
                after = m;
            }
        }

        if(before == undefined && after == undefined) {
            return EMPTY_PWR_DATA;
        }

        let val: TimeValueMapping;

        if(before == undefined) {
            val = after as TimeValueMapping;
        } else if(after == undefined) {
            val = before as TimeValueMapping;
        } else if(after.time == before.time) {
            val = before
        } else {
            const d = after.time - before.time;
            const off = time - before.time;
            const fBefore = off / d;
            const fAfter = 1 - fBefore;

            val = {
                time: time,
                pwr: before.pwr * fBefore + after.pwr * fAfter,
                amps: {
                    i1: before.amps.i1 * fBefore + after.amps.i1 * fAfter,
                    i2: before.amps.i2 * fBefore + after.amps.i2 * fAfter,
                    i3: before.amps.i3 * fBefore + after.amps.i3 * fAfter
                }
            }
        }

        return {
            pwr: val.pwr,
            i1: val.amps.i1,
            i2: val.amps.i2,
            i3: val.amps.i3,
            iMax: Math.max(val.amps.i1, val.amps.i2, val.amps.i3,),
            soc: 0
        };
    }

    public get unitSelector(): PwrSelection {
        return this.pwrSelection;
    }

    public set unitSelector(value: PwrSelection) {
        this.pwrSelection = value;
    }

    public updateUnit(): void {
        if(this.pwrSelection === "soc") {
            this.globalMaxValue = 100;
            this.globalMaxLoadVal = 0;
            this.globalMaxPvVal = 0;
        } else {
            this.globalMaxValue = Math.max(...this.plan.planValues.map(pv => this.getValFromChargePlanVal(pv)));

            this.globalMaxPvVal = 0;
            this.globalMaxLoadVal = 0;
            for(let t = 0; t < this.timeslots; t++) {
                this.globalMaxPvVal = Math.max(this.getPvVal(t), this.globalMaxPvVal);
                this.globalMaxLoadVal = Math.max(this.getLoadVal(t), this.globalMaxLoadVal);
            }
        }
        this.draw("UpdateUnit")
    }

    public get heightPerSingleGraph(): number {
        return (this.canvasHeight - LEGEND_SPACE_Y ) / this.vehicles.size - GRAPH_SPACING
    }

    public get heightPerCombinedGraph(): number {
        return this.canvasHeight-LEGEND_SPACE_Y;
    }

    public get heightPerGraph(): number {
        return this.combined ? this.heightPerCombinedGraph : this.heightPerSingleGraph;
    }

    public get pixPerVal(): number {
        return this.heightPerGraph / this.globalMaxScale;
    }

    public get globalMaxScale(): number {
        let max;
        let beforeAnimation;
        if(this.combined) {
            max = this.globalMaxFuse + this.globalMaxPvVal;
            beforeAnimation = this.globalMaxValue * this.vehicles.size;
            return (max * this.combineState + beforeAnimation * (1 - this.combineState)) * 1.1
        } else {
            max = this.globalMaxValue;
            beforeAnimation = (this.globalMaxFuse + this.globalMaxValue) / this.vehicles.size;
            return (max * (1 - this.combineState) + beforeAnimation * this.combineState) * 1.1
        }
    }

    public get globalMaxFuse(): number {
        let max = 0;
        for(let t = 0; t < this.timeslots; t++) {
            max = Math.max(max, this.getFuseVal(t));
        }
        return max;
    }

    public ngAfterViewInit(): void {
        this.context = this.canvas.nativeElement.getContext('2d') as CanvasRenderingContext2D;

        const patterCanvas = document.createElement("canvas");
        const ctx = patterCanvas.getContext("2d") as CanvasRenderingContext2D;
        patterCanvas.width = 10;
        patterCanvas.height = 10;
        ctx.strokeStyle = "#77777777"
        ctx.beginPath();
        ctx.moveTo(5,0);
        ctx.lineTo(0,5);
        ctx.moveTo(10,5);
        ctx.lineTo(5,10);
        ctx.stroke();
        this.reservationPatter = this.context.createPattern(patterCanvas, "repeat") as CanvasPattern;

        ctx.clearRect(0,0,10,10);
        ctx.strokeStyle = "#FFFF00DD"
        ctx.fillStyle = "#EEEE0077"
        ctx.globalAlpha = 0.4
        ctx.fillRect(0, 0, 10, 10)
        this.solarPatter = this.context.createPattern(patterCanvas, "repeat") as CanvasPattern;

        ctx.globalAlpha = 1
        ctx.clearRect(0,0,40,40);
        ctx.strokeStyle = "#b4804e33"
        ctx.fillStyle = "rgba(180,128,78,0.29)"

        ctx.fillRect(0, 0, 40, 40)
        ctx.beginPath();

        ctx.moveTo(30,0);
        ctx.lineTo(40,10);
        ctx.moveTo(20,0);
        ctx.lineTo(40,20);
        ctx.moveTo(10,0);
        ctx.lineTo(40,30);
        ctx.moveTo(0,0);
        ctx.lineTo(40,40);
        ctx.moveTo(0,10);
        ctx.lineTo(30,40);
        ctx.moveTo(0,20);
        ctx.lineTo(20,40);
        ctx.moveTo(0,30);
        ctx.lineTo(10,40);

        ctx.stroke();
        this.loadsPatter = this.context.createPattern(patterCanvas, "repeat") as CanvasPattern;

        this.resetZoom();
        this.ngOnChanges();
    }

    public mouseMove(event: MouseEvent) {
        this.mouseX = event.offsetX;
        this.mouseY = event.offsetY;
        this.info = this.graphDetails.filter(d => d.overlaps(event.offsetX, event.offsetY)).map(d => d.msg).join(", ");
        if(!this.info) {
            this.info = DEFAULT_HELP_TEXT
            this.canvas.nativeElement.classList.remove("help")
        } else {
            this.canvas.nativeElement.classList.add("help")
        }
        if(!this.combined && this.showDetails) {
            this.draw("Mouse Move with Info enabled");
        }
    }

    public updateSolar() {
        let delta = 0;
        if(!this.pvInBackground && this.mergePvState < 1) {
            delta = this.animationEnabled ? 0.05 : 1
        } else if(this.pvInBackground && this.mergePvState > 0) {
            delta = this.animationEnabled ? -0.05 : -1;
        }
        const newState = Math.max(0, Math.min(1, this.mergePvState + delta));
        if(newState !== this.mergePvState) {
            setTimeout(() => {
                this.mergePvState = newState;
                this.updateSolar();
            }, 25)
        }
        this.draw("UpdateSolar");
    }

    public updateLoad() {
        let delta = 0;
        if(!this.loadInBackground && this.mergeLoadState < 1) {
            delta = this.animationEnabled ? 0.05 : 1;
        } else if(this.loadInBackground && this.mergeLoadState > 0) {
            delta = this.animationEnabled ? -0.05 : -1;
        }
        const newState = Math.max(0, Math.min(1, this.mergeLoadState + delta));
        if(newState !== this.mergeLoadState) {
            setTimeout(() => {
                this.mergeLoadState = newState;
                this.updateLoad();
            }, 25)
        }
        this.draw("UpdateLoad");
    }

    public updateCombined() {
        if(this.combined) {
            if(this.pwrSelection == "soc") {
                this.pwrSelection = "pwr";
            }
        }
        let delta = 0;
        if(this.combined && this.combineState < 1) {
            delta = this.animationEnabled ? 0.05 : 1;
        } else if(!this.combined && this.combineState > 0) {
            delta = this.animationEnabled ? -0.05 : -1;
        }
        const newState = Math.max(0, Math.min(1, this.combineState + delta));
        if(newState !== this.combineState) {
            setTimeout(() => {
                this.combineState = newState;
                this.canvasHeight = Math.max(500, (1-this.combineState) * (this.vehicles.size * SINGLE_VEHICLE_MIN_HEIGHT - 500) + 500)
                this.zoomYMin = 0
                this.zoomYMax = this.canvasHeight;
                this.updateCombined();
            }, 25)
        }
        this.draw("CombineAnimation");
    }

    public drawError(msg: string) {
        this.context.fillStyle = "#000"
        this.context.textAlign = "start";
        this.context.fillText(msg, 10, 10);
    }

    public draw(reason?: string) {
        if(this.drawRequested) {
            return;
        }
        console.count("Trigger Draw: " + reason)
        this.drawRequested = true;
        requestAnimationFrame(() => this.drawNow())
    }

    public drawNow() {
        this.drawRequested = false;
        const t1 = Date.now() ;
        this.graphDetails = [];
        const h = this.canvasHeight;
        const w = this.canvasWidth;
        this.context.fillStyle = "#FFF";
        this.context.lineWidth = 1;
        this.context.fillRect(0,0, w, h);
        if(this.vehicles.size === 0 && !this.combined) {
            this.drawError("Keine Fahrzeuge, einzelne Ansicht nicht möglich");
            return;
        }

        let y0Before = repeat(h - LEGEND_SPACE_Y, this.timeslots);

        let maxPvPix = 0;
        let maxPvVal = 0;
        let y0Solar: number[];
        for(let t = 0; t < this.timeslots; t++) {
            const deltaVal = this.mergePvState * this.getPvVal(t);
            const deltaPix: number = deltaVal * this.pixPerVal;
            maxPvPix = Math.max(maxPvPix, deltaPix);
            maxPvVal = Math.max(maxPvVal, deltaVal);
            y0Before[t] += deltaPix;
        }
        y0Solar = y0Before.map(i => i);
        for(let t = 0; t < this.timeslots; t++) {
            const deltaVal = this.mergeLoadState * this.getLoadVal(t);
            const deltaPix: number = deltaVal * this.pixPerVal;
            y0Before[t] -= deltaPix;
        }

        if(maxPvPix !== 0) {
            for(let t = 0; t < this.timeslots; t++) {
                y0Before[t] -= maxPvPix;
                y0Solar[t] -= maxPvPix;
            }
        }
        const hPerVeh = this.heightPerSingleGraph;
        let idx = this.vehicles.size;

        for(const v of this.vehicles) {
            const y = (--idx) * (hPerVeh + GRAPH_SPACING) + GRAPH_SPACING;
            const y0Sep = repeat(y+hPerVeh, this.timeslots);

            const y0: number[] = [];

            for(let t = 0; t < this.timeslots; t++) {
                y0[t] = (1-this.combineState) * y0Sep[t] + this.combineState * y0Before[t];
            }

            y0Before = this.drawSingleVehicleGraph(v, LEGEND_SPACE_X, hPerVeh, y0);
            if(!this.combined) {
                this.drawReservations(LEGEND_SPACE_X, y + SINGLE_ARROW_SIZE, hPerVeh - SINGLE_ARROW_SIZE, v);
                this.drawName(LEGEND_SPACE_X, this.calcY(y), w-LEGEND_SPACE_X, this.calcH(hPerVeh), v);
                if(this.showEvses) {
                    this.drawEvses(LEGEND_SPACE_X, y, hPerVeh, v);
                }
                this.drawScales(
                    this.calcY(y),
                    this.calcW(w - LEGEND_SPACE_X),
                    this.calcH(hPerVeh)
                );
                this.drawGrid(
                    this.calcY(y),
                    this.calcW(w),
                    this.calcH(hPerVeh),
                    0,
                    this.globalMaxScale);
            }
        }

        if(this.combined && this.showPeak) {
            this.drawPeak(y0Solar);
        }
        if(this.combined && this.showLimit) {
            this.drawLimit(y0Solar);
        }
        if(this.combined && this.showLoads) {
            this.context.fillStyle = this.loadsPatter;
            this.context.beginPath();
            this.context.strokeStyle = "#cb9941"
            for(let t = 0; t < this.timeslots; t++) {
                const x1 = Math.max(LEGEND_SPACE_X, this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + t * this.pixPerT));
                const x2 = this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + (t+1) * this.pixPerT);

                if(x2 < LEGEND_SPACE_X) {
                    continue;
                }

                this.context.lineTo(
                    x1,
                    this.calcY(y0Solar[t])
                );
                this.context.lineTo(
                    x2,
                    this.calcY(y0Solar[t])
                );
            }
            for(let t = this.timeslots-1; t >= 0; t--) {
                const x1 = this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + (t+1) * this.pixPerT);
                const x2 = Math.max(LEGEND_SPACE_X, this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + t * this.pixPerT));

                if(x1 < LEGEND_SPACE_X) {
                    continue;
                }
                this.context.lineTo(
                    x1,
                    this.calcY(y0Solar[t] - this.getLoadVal(t) * this.pixPerVal)
                );
                this.context.lineTo(
                    x2,
                    this.calcY(y0Solar[t] - this.getLoadVal(t) * this.pixPerVal)
                );
            }
            this.context.closePath();
            this.context.fill();

            this.context.beginPath();
            for(let t = this.timeslots-1; t >= 0; t--) {
                const x1 = this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + (t+1) * this.pixPerT);
                const x2 = Math.max(LEGEND_SPACE_X, this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + t * this.pixPerT));

                if(x1 < LEGEND_SPACE_X) {
                    continue;
                }
                let delta = this.getLoadVal(t);
                if(delta <= 0.01) {
                    this.context.moveTo(
                        x2,
                        this.calcY(y0Solar[t] - delta * this.pixPerVal)
                    );
                } else {
                    this.context.lineTo(
                        x1,
                        this.calcY(y0Solar[t] - delta * this.pixPerVal)
                    );
                    this.context.lineTo(
                        x2,
                        this.calcY(y0Solar[t] - delta * this.pixPerVal)
                    );
                }
            }
            this.context.stroke();

        }
        if(this.combined && this.showPv) {
            this.context.fillStyle = this.solarPatter;
            this.context.strokeStyle = "#ffeb00"
            this.context.beginPath();
            for(let t = 0; t < this.timeslots; t++) {
                const x1 = Math.max(LEGEND_SPACE_X, this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + t * this.pixPerT));
                const x2 = this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + (t+1) * this.pixPerT);

                if(x2 < LEGEND_SPACE_X) {
                    continue;
                }

                this.context.lineTo(
                    x1,
                    this.calcY(y0Solar[t])
                );
                this.context.lineTo(
                    x2,
                    this.calcY(y0Solar[t])
                );
            }
            for(let t = this.timeslots-1; t >= 0; t--) {
                const x1 = this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + (t + 1) * this.pixPerT);
                const x2 = Math.max(LEGEND_SPACE_X, this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + t * this.pixPerT));
                if(x1 < LEGEND_SPACE_X) {
                    continue;
                }
                this.context.lineTo(
                    x1,
                    this.calcY(y0Solar[t] - this.getPvVal(t) * this.pixPerVal)
                );
                this.context.lineTo(
                    x2,
                    this.calcY(y0Solar[t] - this.getPvVal(t) * this.pixPerVal)
                );
            }
            this.context.closePath();
            this.context.fill();
            this.context.stroke();
        }
        if(this.combined) {
            this.drawScales(
                this.calcY(-maxPvPix),
                this.calcW(w - LEGEND_SPACE_X),
                this.calcH(h - LEGEND_SPACE_Y)
            );
            this.drawGrid(
                this.calcY(-maxPvPix),
                this.calcW(w),
                this.calcH(h - LEGEND_SPACE_Y),
                -maxPvVal,
                this.globalMaxScale
            );
        }
        this.drawLegendX();

        if(this.showDetails && !this.combined) {
            this.drawDetails();
        }

        this.context.fillStyle = "#88888888";
        if(this.highlightX && !this.highlightY) {
            this.context.fillRect(this.highlightXStart, 0, this.highlightXEnd - this.highlightXStart, this.canvasWidth);
        }
        if(!this.highlightX && this.highlightY) {
            this.context.fillRect(0, this.highlightYStart, this.canvasWidth,this.highlightYEnd - this.highlightYStart);
        }
        if(this.highlightX && this.highlightY) {
            this.context.fillRect(
                this.highlightXStart,
                this.highlightYStart,
                this.highlightXEnd - this.highlightXStart,
                this.highlightYEnd - this.highlightYStart);
        }

        this.drawCurrentTimeSeparator();
        const t2 = Date.now();
        const duration = t2 - t1;
        this.context.fillStyle = '#6668';
        this.context.textAlign = "center";
        this.context.textBaseline = "top";
        if(this.isDev && duration < 1000) {
            this.context.fillText("Render duration: " + duration.toFixed(0) + " ms", this.canvasWidth / 2, 0);
        } else if(this.isDev) {
            this.context.fillText("Render duration: " + (duration / 1000).toFixed(2) + " s", this.canvasWidth / 2, 0);
        }
    }

    private drawCurrentTimeSeparator() {
        const t = this.timeToSlot(Date.now());
        const x = this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + this.pixPerT * t);
        if (x < LEGEND_SPACE_X) {
            return;
        }
        this.context.beginPath();
        this.context.moveTo(x, 0);
        this.context.lineTo(x, this.canvasHeight);

        this.context.strokeStyle = '#F00';
        this.context.fillStyle = '#F00';
        this.context.lineWidth = 3;
        this.context.textAlign = "left";
        this.context.textBaseline = "top"
        this.context.fillText("Jetzt", x + 10, this.canvasHeight / 2);
        this.context.stroke();
    }

    private drawPeak(y0Solar: number[]) {
        this.context.setLineDash([3, 3]);
        this.context.strokeStyle = '#333';
        this.context.fillStyle = '#333';
        this.context.beginPath();

        let first = true;
        for (let t = 0; t < this.timeslots; t++) {
            const x = this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + this.pixPerT * t);
            if(x < LEGEND_SPACE_X) {
                continue;
            }
            if(first) {
                this.context.moveTo(
                    x,
                    this.calcY(
                        y0Solar[t] -
                        (this.getPeakVal(t) + this.getPvVal(t) - this.getLoadVal(t) * (1-this.mergeLoadState)) * this.pixPerVal
                    )
                );
                first = false;
            }
            this.context.lineTo(
                x,
                this.calcY(
                    y0Solar[t] -
                    (this.getPeakVal(t) + this.getPvVal(t) - this.getLoadVal(t) * (1-this.mergeLoadState)) * this.pixPerVal
                )
            );
        }
        this.context.stroke();
        this.context.setLineDash([]);
        const y = y0Solar[this.timeslots-1] - (this.getPeakVal(this.timeslots - 1) + this.getPvVal(this.timeslots - 1) - this.getLoadVal(this.timeslots - 1) * (1-this.mergeLoadState)) * this.pixPerVal
        const x = this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + this.pixPerT * this.timeslots - 1);
        this.context.textAlign = "right";
        this.context.textBaseline = "top"
        this.context.fillText("höchster Peak", x, y);

    }


    private drawLimit(y0Solar: number[]) {
        this.context.strokeStyle = '#F00';
        this.context.fillStyle = '#F00';
        this.context.beginPath();

        let first = true;
        for (let t = 0; t < this.timeslots; t++) {
            const x = this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + this.pixPerT * t);
            if(x < LEGEND_SPACE_X) {
                continue;
            }
            if(first) {
                this.context.moveTo(
                    x,
                    this.calcY(
                        y0Solar[t] -
                        (this.getFuseVal(t) + this.getPvVal(t) - this.getLoadVal(t) * (1-this.mergeLoadState)) * this.pixPerVal
                    )
                );
                first = false;
            }
            this.context.lineTo(
                x,
                this.calcY(
                    y0Solar[t] -
                    (this.getFuseVal(t) + this.getPvVal(t) - this.getLoadVal(t) * (1-this.mergeLoadState)) * this.pixPerVal
                )
            );
        }
        this.context.stroke();
        this.context.setLineDash([]);

        const y = y0Solar[this.timeslots-1] -
            (this.getFuseVal(this.timeslots-1) + this.getPvVal(this.timeslots-1) - this.getLoadVal(this.timeslots-1) * (1-this.mergeLoadState)) * this.pixPerVal
        const x = this.calcX(LEGEND_SPACE_X + SINGLE_ARROW_SIZE + this.pixPerT * this.timeslots - 1);
        this.context.textAlign = "right";
        this.context.textBaseline = "top"
        this.context.fillText("Anschlusslimit", x, y);
    }

    private drawReservations(x: number, y: number, h: number, v: number) {
        this.context.strokeStyle = this.getStyleById(v);
        this.context.fillStyle = this.reservationPatter;

        const reservation = this.reservations[v];
        if(reservation) {
            for(const r of reservation) {
                const start = x + this.timeToPix(r.start);
                const end = x + this.timeToPix(r.end);
                this.context.fillRect(
                    this.calcX(start),
                    this.calcY(y),
                    this.calcW(end - start),
                    this.calcH(h)
                );
                this.graphDetails.push(new GraphDetail(
                    this.calcX(start),
                    this.calcY(y),
                    this.calcW(end-start),
                    this.calcH(h),
                    "Reservierung für " + this.vehicleNames[v] + ". Es werden " + r.reservation.targetSoc.toFixed(0) + "% Batterie benutzt."));
            }
        }
    }

    private drawSingleVehicleGraph(vehicleId: number, x: number, h: number, y0: number[]): number[] {
        this.context.strokeStyle = this.getStyleById(vehicleId, "FF")
        this.context.lineWidth = 1;
        this.context.fillStyle = this.getStyleById(vehicleId, "20");

        this.context.beginPath();

        const ret: number[] = [];
        let first = true;

        for(let t = 0; t < this.timeslots; t++) {
            const localX1 = Math.max(LEGEND_SPACE_X, this.calcX(x+SINGLE_ARROW_SIZE + t * this.pixPerT));
            const localX2 = this.calcX(x+SINGLE_ARROW_SIZE + (t+1) * this.pixPerT);

            if(localX2 < LEGEND_SPACE_X) {
                continue;
            }

            const pv = ChargePlanNewComponent.getFromMatrix(vehicleId, t, this.planValuesByVehicle);
            let localY = y0[t];
            localY -= this.getVal(vehicleId, t) * this.pixPerVal;
            if(pv != null && pv.evse != null && (this.calcH(y0[t] - localY) > 0)) {
                this.graphDetails.push(new GraphDetail(
                    localX1,
                    this.calcY(localY),
                    localX2 - localX1,
                    this.calcH(y0[t] - localY),
                    "Fahrzeug '" + this.getVehicleName(vehicleId) +
                    "' lädt an '" + this.getEvseName(pv.evse) + "' mit " +
                    pv.phases.length + "x " + pv.ampsCp +"A (" + (pv.phases.length * pv.ampsCp * 235 / 1000) + "kW)." +
                    " Ladestand: " + this.getSocStr(vehicleId, t)));
            }
            ret[t] = localY;

            if(first) {
                this.context.moveTo(
                    localX1,
                    this.calcY(localY)
                );
                first = false;
            }
            this.context.lineTo(
                localX1,
                this.calcY(localY)
            );
            this.context.lineTo(
                localX2,
                this.calcY(localY)
            );
        }

        for(let t = this.timeslots; t >= 0; t--) {
            const localX1 = Math.max(LEGEND_SPACE_X, this.calcX(x+SINGLE_ARROW_SIZE + t * this.pixPerT));
            const localX2 = this.calcX(x+SINGLE_ARROW_SIZE + (t+1) * this.pixPerT);

            if(localX2 < LEGEND_SPACE_X) {
                continue;
            }

            this.context.lineTo(
                localX2,
                this.calcY(y0[t])
            );
            this.context.lineTo(
                localX1,
                this.calcY(y0[t])
            );
        }
        this.context.closePath();
        this.context.fill();

        first = true;
        this.context.beginPath();
        for(let t = 0; t < this.timeslots; t++) {
            const localX1 = Math.max(LEGEND_SPACE_X, this.calcX(x+SINGLE_ARROW_SIZE + t * this.pixPerT));
            const localX2 = this.calcX(x+SINGLE_ARROW_SIZE + (t+1) * this.pixPerT);

            if(localX2 < LEGEND_SPACE_X) {
                continue;
            }

            let localY = y0[t];
            localY -= this.getVal(vehicleId, t) * this.pixPerVal;
            let localYNext;
            if(this.timeslots !== t + 1) {
                localYNext = y0[t+1] - this.getVal(vehicleId, t + 1) * this.pixPerVal;
            } else {
                localYNext = localY;
            }

            if(first) {
                this.context.moveTo(
                    localX1,
                    this.calcY(localY)
                );
                first = false;
                this.context.lineTo(
                    localX1,
                    this.calcY(localY)
                );
            }

            this.context.lineTo(
                localX1,
                this.calcY(localY)
            );
            this.context.lineTo(
                localX2,
                this.calcY(localY)
            );
            this.context.lineTo(
                localX2,
                this.calcY(localYNext)
            );
        }
        this.context.stroke();

        return ret;
    }

    private drawDetails() {
        const time = this.pixToTime(this.calcXReverse(this.mouseX - LEGEND_SPACE_X));
        const slot = Math.floor(this.timeToSlot(time));

        const y = this.canvasHeight - LEGEND_SPACE_Y - this.calcYReverse(this.mouseY);
        const vehIdx = Math.floor(y / (this.heightPerSingleGraph + GRAPH_SPACING));
        if(vehIdx < 0) {
            return;
        }
        const vehicleId = [...this.vehicles][vehIdx];

        const pv = this.planValuesByVehicle[vehicleId][slot];

        const mode = this.mouseX < (this.canvasWidth / 2); // true = right, false = left

        if(pv) {
            this.context.fillStyle="#DDD";
            this.context.textBaseline="top";
            this.context.textAlign=mode ? "right": "left";

            const margin = 3;
            const space = 1;
            const lineHeight = 14 + space;

            let w = margin * 2;
            for(const s of pv.reasonDetails) {
                w = Math.max(this.context.measureText(s).width, w);
            }

            this.context.fillRect(mode ? this.canvasWidth - w - margin - margin : 0,0, w + margin + margin, pv.reasonDetails.length * lineHeight + margin + margin);

            this.context.fillStyle="#000";
            let y = margin;
            for(const s of pv.reasonDetails) {
                this.context.fillText(s, mode ? this.canvasWidth - margin : margin,y);
                y += lineHeight;
            }
        }
    }

    private getSoc(vehicle: number, t: number): number {
        const val = ChargePlanNewComponent.getFromMatrix(vehicle, t, this.socs);
        if(val === undefined) {
            return NaN;
        }
        return val;
    }

    private getSocStr(vehicle: number, t: number): string{
        const soc = this.getSoc(vehicle, t);
        if(isNaN(soc)) {
            return "-%";
        }
        return soc.toFixed(1) + "%";
    }

    private drawScales(y: number, w: number, h: number) {
        this.context.strokeStyle = '#000';
        this.context.fillStyle = '#fff';
        this.context.lineWidth = 1;

        this.context.beginPath();
        this.context.moveTo(
            LEGEND_SPACE_X - SINGLE_ARROW_SIZE,
            y - TWO_ARROWS_SIZE
        );
        this.context.lineTo(
            LEGEND_SPACE_X,
            y - SINGLE_ARROW_SIZE - TWO_ARROWS_SIZE
        );
        this.context.lineTo(
            LEGEND_SPACE_X + SINGLE_ARROW_SIZE,
            y - TWO_ARROWS_SIZE
        );
        this.context.moveTo(
            LEGEND_SPACE_X,
            y - SINGLE_ARROW_SIZE - TWO_ARROWS_SIZE
        );
        this.context.lineTo(
            LEGEND_SPACE_X,
            y + h
        );
        this.context.lineTo(
            LEGEND_SPACE_X + w - SINGLE_ARROW_SIZE,
            y + h
        );
        this.context.lineTo(
            LEGEND_SPACE_X + w - TWO_ARROWS_SIZE,
            y + h + SINGLE_ARROW_SIZE
        );
        this.context.moveTo(
            LEGEND_SPACE_X + w - TWO_ARROWS_SIZE,
            y + h - SINGLE_ARROW_SIZE
        );
        this.context.lineTo(
            LEGEND_SPACE_X + w - SINGLE_ARROW_SIZE,
            y + h
        );
        this.context.stroke();
    }

    private drawGrid(y: number, w: number, h: number, min: number, max: number) {
        const steps = this.calcGridSteps(h, min, max);
        const x = LEGEND_SPACE_X;
        this.context.font = "15px Arial"
        this.context.textAlign = "end";
        this.context.textBaseline = "middle";
        this.context.fillStyle = "#333"
        this.context.strokeStyle = "#00000033"
        this.context.lineWidth = 1;
        this.context.beginPath();
        for(const dy of steps) {
            const yPix = y+h-dy*this.calcH(this.pixPerVal);
            this.context.moveTo(
                LEGEND_SPACE_X + SINGLE_ARROW_SIZE,
                yPix
            );
            this.context.lineTo(
                x+w,
                yPix
            );
            if(yPix > 10 && yPix < this.canvasHeight - 10) {
                this.context.fillText(
                    dy.toFixed(1) + " " + this.getUnit(),
                    LEGEND_SPACE_X - SINGLE_ARROW_SIZE,
                    yPix,
                    LEGEND_SPACE_X
                );
            }
        }

        const baseX = LEGEND_SPACE_X + SINGLE_ARROW_SIZE;
        const stepSize = this.calcLegendXStepSize();
        const initialT = this.calcLegendXInitialT(stepSize);

        for(let t = initialT; t < this.timeslots; t+=stepSize) {
            const x1 = this.calcX(baseX) + t * this.calcW(this.pixPerT);
            if(x1 < LEGEND_SPACE_X) {
                continue;
            }
            this.context.moveTo(
                x1,
                y
            );
            this.context.lineTo(
                x1,
                this.combined ? (this.canvasHeight-LEGEND_SPACE_Y) : (y+h)
            );
        }

        this.context.stroke();
    }

    private drawLegendX() {
        this.context.textAlign = "center"
        this.context.textBaseline = "bottom";
        this.context.fillStyle = "#000";
        const baseX = LEGEND_SPACE_X + SINGLE_ARROW_SIZE;
        const timeStepSize = this.calcLegendXStepSize();
        const initialT = this.calcLegendXInitialT(timeStepSize);

        for(let t = initialT; t < this.timeslots; t+=timeStepSize) {
            const x1 = this.calcX(baseX) + t * this.calcW(this.pixPerT);
            if(x1 < LEGEND_SPACE_X) {
                continue;
            }
            this.context.fillText(
                this.formatTime(this.slotToTime(t)),
                x1,
                this.canvasHeight - 1
            );
        }
    }

    private calcLegendXInitialT(timeStepSize: number) {
        const timePerStep = timeStepSize * 5 * MINUTE;
        const stepBefore = this.calculateStartDate().getTime() - (this.calculateStartDate().getTime() % timePerStep);
        const firstStep = stepBefore + timePerStep;
        const firstOffset = firstStep - this.calculateStartDate().getTime();
        return firstOffset / 5 / MINUTE;
    }

    private calcLegendXStepSize() {
        const pixPerHour = this.calcW(12 * this.pixPerT);
        if (pixPerHour < 100) {
            return 24;
        } else if (pixPerHour < 200) {
            return 12;
        } else if (pixPerHour < 300) {
            return 6;
        } else if (pixPerHour < 400) {
            return 4;
        } else if (pixPerHour < 500) {
            return 2;
        } else {
            return 1;
        }
    }

    private formatTime(time: number) {
        const offset = new Date().getTimezoneOffset();
        const min = (24*60+Math.floor(time % HOUR / MINUTE) - offset)% 60;
        const hour = (24 + Math.floor(time % DAY / HOUR) - offset / 60) % 24;
        if(hour === 0) {
            const date = new Date(time);
            let day: string;
            switch (date.getDay()) {
                case 0: day = "So"; break;
                case 1: day = "Mo"; break;
                case 2: day = "Di"; break;
                case 3: day = "Mi"; break;
                case 4: day = "Do"; break;
                case 5: day = "Fr"; break;
                case 6: day = "Sa"; break;
                default: day = "XX";
            }
            return day + " " + this.twoDigest(date.getDate()) + "." + this.twoDigest(date.getMonth()+1)
        }

        return this.twoDigest(hour) + ":" + this.twoDigest(min);
    }

    private twoDigest(num: number): string {
        if(num > 9) {
            return num.toFixed(0);
        }
        return "0"+num.toFixed(0);
    }

    private slotToTime(slot: number): number {
        return slot / this.timeslots * (DAY * this.numberOfDays) + this.calculateStartDate().getTime();
    }

    private timeToSlot(time: number): number {
        return (time - this.calculateStartDate().getTime()) / (DAY * this.numberOfDays) * this.timeslots;
    }

    private getStyleById(vehicleId: number, alpha = "77"): string {
        // Die Farbe wird auf Basis der ID ausgewählt. Das hat einen hohen Wiedererkennungswert.
        // Das Auto von Johann wird also egal wann er auf den Plan guckt, immer die gleiche Farbe
        // haben. Da Fahrzeuge oft als Gruppe angelegt werden, ist das Risiko einer Kollision
        // gering. Bei Kollisionen muss man dann halt den Text lesen.
        // Ich denke das hier ist besser als jede Variante, zufällig die Farben zu vergeben.
        switch (Math.abs(vehicleId) % 19) {
            case 0: return "#000000" + alpha;
            case 1: return "#0000FF" + alpha;
            case 2: return "#00FF00" + alpha;
            case 3: return "#00FFFF" + alpha;
            case 4: return "#FF00FF" + alpha;
            case 5: return "#000088" + alpha;
            case 6: return "#008800" + alpha;
            case 7: return "#008888" + alpha;
            case 8: return "#FF88FF" + alpha;
            case 9: return "#880000" + alpha;
            case 10: return "#880088" + alpha;
            case 11: return "#884444" + alpha;
            case 12: return "#888888" + alpha;
            case 13: return "#0088FF" + alpha;
            case 14: return "#8800FF" + alpha;
            case 15: return "#8888FF" + alpha;
            case 16: return "#00FF88" + alpha;
            case 17: return "#88FF00" + alpha;
            case 18: return "#FF8888" + alpha;
        }
        return "#F00";
    }

    private timeToPix(time: number): number {
        const dt = Math.max(0, time - this.plan.initTime);
        const pixPerTime = this.pixPerMillisecond;
        return SINGLE_ARROW_SIZE + pixPerTime * dt;
    }

    private pixToTime(pix: number): number {
        return (pix - SINGLE_ARROW_SIZE) / this.pixPerMillisecond + this.plan.initTime;
    }

    private getVal(id: number, t: number): number {
        if(this.pwrSelection === "soc") {
            const soc = this.getSoc(id, t);
            if(isNaN(soc)) {
                return 0;
            }
            return soc;
        }
        const pv = ChargePlanNewComponent.getFromMatrix(id, t, this.planValuesByVehicle);
        if(!pv) {
            return 0;
        }
        return this.getValFromChargePlanVal(pv);
    }

    private getValFromChargePlanVal(pv: ChargePlanValue): number {
        switch (this.pwrSelection) {
            case "pwr":
                return pv.ampsCp * 235 * pv.phases.length / 1000
            case "soc":
                if(pv.vehicle) {
                    return this.socs[pv.vehicle][pv.t];
                }
                return 0;
            case "i1":
                return pv.phases.indexOf("L1") == -1 ? 0 : pv.ampsCp;
            case "i2":
                return pv.phases.indexOf("L2") == -1 ? 0 : pv.ampsCp;
            case "i3":
                return pv.phases.indexOf("L3") == -1 ? 0 : pv.ampsCp;
            case "iMax":
                return pv.ampsCp;
        }
    }

    private getUnit(): string {
        switch (this.pwrSelection) {
            case "pwr":
                return "kW"
            case "soc":
                return "%"
            case "i1":
            case "i2":
            case "i3":
            case "iMax":
                return "A"
        }
    }

    private calcGridSteps(h: number, min: number, max: number): number[] {
        let stepCont = Math.floor(h / 50);
        if(stepCont <= 1) {
            return [max];
        }

        let stepSize = max / stepCont;
        if (stepSize == 0) {
            return [max];
        }

        let factor = 1;
        while(stepSize > 10) {
            stepSize /= 10;
            factor *= 10;
        }
        while(stepSize < 1) {
            stepSize *= 10;
            factor /= 10;
        }
        stepSize = Math.round(stepSize) * factor;

        stepCont = max / stepSize;

        const ret: number[] = [];
        for(let i = Math.ceil(min / stepSize); i <= stepCont; i++) {
            const val = i * stepSize;
            ret.push(val);
        }

        return ret;
    }

    private drawName(x: number, y: number, w: number, h: number, v: number) {
        const name = this.vehicleNames[v] || ("Fahrzeug " + v)
        this.context.textAlign = "end";
        this.context.textBaseline = "middle";
        this.context.fillStyle =" #000"
        this.context.fillText(
            name,
            x + w,
            y + h/2
        );
    }

    private drawEvses(x: number, y: number, h: number, v: number) {
        const phases = this.pluggedPhasesVehicles[v] || [];

        for(const p of phases) {
            this.context.fillStyle = this.getStyleById(p.evse);
            const start = Math.max(LEGEND_SPACE_X, this.calcX(x + SINGLE_ARROW_SIZE + p.start * this.pixPerT));
            const end = this.calcX(x + SINGLE_ARROW_SIZE + (p.end + 1) * this.pixPerT);

            if(end <= LEGEND_SPACE_X) {
                continue;
            }
            this.context.fillRect(
                start,
                this.calcY(y + h + 2),
                end - start,
                this.calcH(4)
            );
            this.graphDetails.push(new GraphDetail(
                start,
                this.calcY(y + h + 2),
                end - start,
                this.calcH(4),
                "Fahrzeug " + this.getVehicleName(v) + " ist in Ladepunkt " + this.getEvseName(p.evse) + " eingesteckt."))
        }
    }

    private getVehicleName(v: number) {
        return this.vehicleNames[v] || "Fahrzeug " + v;
    }

    private getEvseName(evse: number) {
        return this.evseNames[evse] || "Ladepunkt " + evse;
    }

    private getPvVal(t: number) {
        if(this.pwrSelection === "pwr"){
            return this.pvBySlot[t][this.pwrSelection] / 1000;
        } else {
            return this.pvBySlot[t][this.pwrSelection];
        }
    }
    private getLoadVal(t: number) {
        if(this.pwrSelection === "pwr"){
            return this.loadBySlot[t][this.pwrSelection] / 1000;
        } else {
            return this.loadBySlot[t][this.pwrSelection];
        }
    }

    private getPeakVal(t: number) {
        if(this.pwrSelection === "pwr"){
            return this.peakBySlot[t][this.pwrSelection] / 1000;
        } else {
            return this.peakBySlot[t][this.pwrSelection];
        }
    }

    private getFuseVal(t: number) {
        if(this.pwrSelection === "pwr"){
            return this.fuseBySlot[t][this.pwrSelection] / 1000;
        } else {
            return this.fuseBySlot[t][this.pwrSelection];
        }
    }

    public toggleTime():void {
        this.timeVisible = !this.timeVisible;
        localStorageSave("CHARGE_PLAN_TIME_VISIBLE", this.timeVisible ? "true" : "false");
    }
    public toggleMessages(): void {
        this.messagesVisible = !this.messagesVisible;
        localStorageSave("CHARGE_PLAN_MESSAGES_VISIBLE", this.messagesVisible ? "true" : "false");
    }


    public highlightHelp(): void {
        if(this.info === DEFAULT_HELP_TEXT) {
            return;
        }

        this.helpBlink = true;
        clearTimeout(this.helpBlinkTimeout);
        this.helpBlinkTimeout = setTimeout(() => this.helpBlink = false, 2000)
    }

    private calculateStartDate(): Date {
        const newDate = new Date();
        switch (this.selectedDateRange) {
            case 'week' :
                newDate.setDate(newDate.getDate() - 7);
                break;
            case 'today':
                newDate.setUTCHours(0,0,0,0);
                break;
            case 'tMonth':
                newDate.setDate(1);
                break;
            case 'tQuarter':
                newDate.setDate(1);
                newDate.setMonth(Math.ceil(newDate.getMonth() / 3));
                break;
            default:
        }
        return newDate;
    }
    public changeDateRangeSelection(): void {
        let newDate = this.calculateStartDate();
        this.dateChangeEvent.emit({startTime: newDate.getTime()});
    }
}
