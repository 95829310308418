import {Component, Input, OnInit} from '@angular/core';
import {Basis, DataValueNum, Evse} from '@io-elon-common/frontend-api';
import {num} from '../../../../../shared/helper/util-functions';
import {EvseService} from '../../../../evse/service/evse.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-evse-tree-view-node',
    templateUrl: './evse-tree-view-node.component.html',
    styleUrls: ['./evse-tree-view-node.component.scss']
})
export class EvseTreeViewNodeComponent implements OnInit {

    @Input()
    public evse!: Evse;

    @Input()
    public basis!: Basis;

    public constructor(
        private readonly toastr: ToastrService,
        private readonly evseService: EvseService,
    ) {
    }

    public ngOnInit(): void {
    }

    public getPwr(): number {
        const live = this.evse.liveData;
        let pwr = this.getP(live.evseP1, live.evseI1, live.evseU1) +
            this.getP(live.evseP2, live.evseI2, live.evseU2) +
            this.getP(live.evseP3, live.evseI3, live.evseU3);
        return pwr / 1000;
    }

    public getMaxPwr(): number {
        return this.evse.maxI * 235 / 1000 * 3;
    }

    private getP(pwr: DataValueNum | undefined, amps: DataValueNum | undefined, volt: DataValueNum | undefined): number {
        return num(pwr?.val, num(amps?.val, 0) * num(volt?.val, 235));
    }

    public async handleEditEvse(evse: Evse): Promise<void> {
        await this.evseService.showEditDialog(evse, this.basis.powerSupplies);
    }

    public  async handleDeleteEvse(evse: Evse): Promise<void> {
        await this.evseService.showDeleteDialog(evse, {});
    }
}
