import {Pipe, PipeTransform} from '@angular/core';
import {Evse, Vehicle} from '@io-elon-common/frontend-api';
import {PlugState} from './plug-state.enum';

@Pipe({
    name: 'plugStateVehicle'
})
export class PlugStateVehiclePipe implements PipeTransform {

    public constructor() {}

    transform(vehicle: Vehicle, evseAlw?: boolean | null): PlugState {
        // Table with defined Texts is available in Redmine #2987 (Attachment)

        const plugged = vehicle.liveData.plugged?.val;
        const charging = vehicle.liveData.charging?.val;

        const valAlw = evseAlw == null ? 0 : evseAlw ? 200 : 100;
        const valPlugged = plugged == null ? 0 : plugged ? 20 : 10;
        const valCharging = charging == null ? 0 : charging ? 2 : 1;

        const val = valAlw + valPlugged + valCharging;

        switch (val) {
            case 0: return PlugState.PLUG_STATE_UNKNOWN
            case 1: return PlugState.PLUG_STATE_UNKNOWN
            case 2: return PlugState.PLUG_STATE_CHARGING
            case 10: return PlugState.PLUG_STATE_UNPLUGGED
            case 11: return PlugState.PLUG_STATE_UNPLUGGED
            case 12: return PlugState.PLUG_STATE_ERROR
            case 20: return PlugState.PLUG_STATE_UNKNOWN
            case 21: return PlugState.PLUG_STATE_PAUSED
            case 22: return PlugState.PLUG_STATE_CHARGING
            case 100: return PlugState.PLUG_STATE_UNKNOWN
            case 101: return PlugState.PLUG_STATE_PAUSED_EVSE
            case 102: return PlugState.PLUG_STATE_ERROR
            case 110: return PlugState.PLUG_STATE_ERROR
            case 111: return PlugState.PLUG_STATE_ERROR
            case 112: return PlugState.PLUG_STATE_ERROR
            case 120: return PlugState.PLUG_STATE_PAUSED_EVSE
            case 121: return PlugState.PLUG_STATE_PAUSED_EVSE
            case 122: return PlugState.PLUG_STATE_ERROR
            case 200: return PlugState.PLUG_STATE_UNKNOWN
            case 201: return PlugState.PLUG_STATE_PAUSED
            case 202: return PlugState.PLUG_STATE_CHARGING
            case 210: return PlugState.PLUG_STATE_ERROR
            case 211: return PlugState.PLUG_STATE_ERROR
            case 212: return PlugState.PLUG_STATE_ERROR
            case 220: return PlugState.PLUG_STATE_UNKNOWN
            case 221: return PlugState.PLUG_STATE_PAUSED_VEHICLE
            case 222: return PlugState.PLUG_STATE_CHARGING
            default: return PlugState.PLUG_STATE_ERROR
        }
    }
}

@Pipe({
    name: 'plugStateEvse'
})
export class PlugStateEvsePipe implements PipeTransform {

    public constructor() {}

    transform(evse: Evse): PlugState {
        // Table with defined Texts is available in Redmine #2987 (Attachment)

        const allowed = evse.liveData.evseAlw?.val;
        const plugged = evse.liveData.evsePlugged?.val;
        const charging = evse.liveData.evseCharging?.val;

        const valAlw = allowed == null ? 0 : allowed ? 200 : 100;
        const valPlugged = plugged == null ? 0 : plugged ? 20 : 10;
        const valCharging = charging == null ? 0 : charging ? 2 : 1;

        const val = valAlw + valPlugged + valCharging;

        switch (val) {
            case 0: return PlugState.PLUG_STATE_UNKNOWN
            case 2: return PlugState.PLUG_STATE_CHARGING
            case 1: return PlugState.PLUG_STATE_UNKNOWN
            case 20: return PlugState.PLUG_STATE_UNKNOWN
            case 22: return PlugState.PLUG_STATE_CHARGING
            case 21: return PlugState.PLUG_STATE_PAUSED
            case 10: return PlugState.PLUG_STATE_UNPLUGGED
            case 12: return PlugState.PLUG_STATE_ERROR
            case 11: return PlugState.PLUG_STATE_UNPLUGGED
            case 200: return PlugState.PLUG_STATE_UNKNOWN
            case 202: return PlugState.PLUG_STATE_CHARGING
            case 201: return PlugState.PLUG_STATE_UNKNOWN
            case 220: return PlugState.PLUG_STATE_UNKNOWN
            case 222: return PlugState.PLUG_STATE_CHARGING
            case 221: return PlugState.PLUG_STATE_PAUSED_VEHICLE
            case 210: return PlugState.PLUG_STATE_UNPLUGGED_ALW
            case 212: return PlugState.PLUG_STATE_ERROR
            case 211: return PlugState.PLUG_STATE_UNPLUGGED_ALW
            case 100: return PlugState.PLUG_STATE_PAUSED_EVSE
            case 102: return PlugState.PLUG_STATE_ERROR
            case 101: return PlugState.PLUG_STATE_UNKNOWN
            case 120: return PlugState.PLUG_STATE_PAUSED_EVSE
            case 122: return PlugState.PLUG_STATE_ERROR
            case 121: return PlugState.PLUG_STATE_PAUSED_EVSE
            case 110: return PlugState.PLUG_STATE_UNPLUGGED
            case 112: return PlugState.PLUG_STATE_ERROR
            case 111: return PlugState.PLUG_STATE_UNPLUGGED
            default: return PlugState.PLUG_STATE_ERROR
        }
    }
}
