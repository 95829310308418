<div class="table-frame">
    <h2>RFID-Karten</h2>
    <div *ngIf="(rfids | async) as rfids ; else loading">
        <div *ngIf="(users | async) as users ; else loading">
            <div *ngIf="(vehicles | async) as vehicles ; else loading">
                <div *ngIf="(customers | async) as customers ; else loading">
                    <div class="mb-2">Filter: <input type="text" [(ngModel)]="filter" (blur)="updateUrl()"></div>
                    <app-rfid-table [rfids]="rfids" [filter]="filter"></app-rfid-table>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons d-flex flex-row justify-content-end gap-3 align-items-stretch">
        <div class="with-background mat-elevation-z4 d-flex flex-row gap-1" style="border-radius: 6px">
            <button mat-button color="primary" (click)="downloadTemplate()" matTooltip="Laden der auszufüllenden Excel Vorlage, zum hinzufügen mehrerer RFID">
                <mat-icon>download</mat-icon>
            </button>
            <button mat-button color="primary" (click)="fileInput.click()" matTooltip="Hochladen der zuvor ausgefüllten Excel Vorlage, zum importieren von RFID">
                <mat-icon>upload</mat-icon>
            </button>
            <mat-icon class="cursor-style" [matTooltip]=getHelpText() [matTooltipClass]="'pre-tooltip'">help</mat-icon>
            <input hidden (change)="handleFileInput($event.target.files)" (click)="clearFileHistory($event.target)" #fileInput type="file" id="file">
        </div>
        <button mat-raised-button color="primary" (click)="handleNewRfid()">RFID-Karte hinzufügen</button>
    </div>
</div>
<ng-template #loading>Loading...</ng-template>
