import {Component, Inject, OnInit} from '@angular/core';
import {SystemInfoSupportedEvseActions} from '@io-elon-common/frontend-api/lib/model/systemInfoSupportedEvseActions';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EvseService} from '../../service/evse.service';
import {ToastrService} from 'ngx-toastr';
import {EvseAction, Rfid, SystemInfoSupportedEvseActionArguments} from '@io-elon-common/frontend-api';
import {AuthService} from '../../../../shared/guards/auth.service';
import {RfidService} from '../../../rfid/service/rfid.service';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

export interface EvseActionsDialogData {
    evseId: number,
    data: Array<SystemInfoSupportedEvseActions>
}
@Component({
  selector: 'app-evse-actions-dialog',
  templateUrl: './evse-actions-dialog.component.html',
  styleUrls: ['./evse-actions-dialog.component.scss']
})
export class EvseActionsDialogComponent implements OnInit {
    public data: Array<SystemInfoSupportedEvseActions>;
    public selectedAction: SystemInfoSupportedEvseActions | undefined;
    public argumentsValues: { [key: string]: string; } = {};
    public resultText = "";
    public loadingResults = false;
    public isDev = false;
    public filterdRfids!: Observable<Rfid[] | undefined>;
    private rfids!: Rfid[];
    public rfidControl = new FormControl('');
    public argumentType = SystemInfoSupportedEvseActionArguments.ArgumentTypeEnum;
    public constructor( private dialogRef: MatDialogRef<EvseActionsDialogComponent>,
                 @Inject(MAT_DIALOG_DATA) public dialogData: EvseActionsDialogData,
                 private evseService: EvseService,
                 private toastr: ToastrService,
                 private  rfidService: RfidService,
                 private readonly authService: AuthService) {
        this.dialogRef.disableClose = true;
        this.isDev = this.authService.isDeveloper();
        this.data = dialogData.data;
    }

    async ngOnInit(): Promise<void> {
        this.rfids = await this.rfidService.getAllPromise();
        this.filterdRfids = this.rfidControl.valueChanges.pipe(
            startWith(''),
            map(value => {
                if (typeof value === 'string') return this.filterRfids(value);
                return this.rfids.slice();
            })
        );
    }

    private filterRfids(search: string): Rfid[] {
        const filter = search.toLowerCase();

        return this
            .rfids
            .filter(rfid => rfid.canView)
            .filter(rfid => rfid.name.toLowerCase().includes(filter)
                || rfid.token.includes(filter)
                || rfid.id.toString().startsWith(filter));

    }

    public async sendAction(): Promise<void> {
        console.log(this.argumentsValues);
        if (this.selectedAction === undefined) {
            return;
        }
        this.loadingResults = true;
        this.resultText = "";
        const evseAction: EvseAction = {name: this.selectedAction.name, arguments: []};
        let invalidPatternValues = "";
        this.selectedAction.arguments.forEach(argument => {
            if (this.checkValidation(this.argumentsValues[argument.name], argument.validationPattern)) {
                evseAction.arguments.push(
                    {
                        name: argument.name,
                        value: this.argumentsValues[argument.name]
                    })
            } else {
                invalidPatternValues = invalidPatternValues + "ungültiger wert " + argument.displayName + "<br/>";
            }
        });
        if (evseAction.arguments.length === this.selectedAction.arguments.length) {
            this.resultText = (await this.evseService.executeAction(this.dialogData.evseId, evseAction)).result;
        } else {
            this.toastr.warning(invalidPatternValues);
        }
        this.loadingResults = false;
    }

    public get devActions(): SystemInfoSupportedEvseActions[] {
        return this.data.filter(a => a.isDevOnly);
    }

    public get userHiddenActions(): SystemInfoSupportedEvseActions[] {
        return this.data.filter(a => !a.isDevOnly && !a.isVisibleToUsers);
    }
    public get userActions(): SystemInfoSupportedEvseActions[] {
        return this.data.filter(a => !a.isDevOnly && a.isVisibleToUsers);
    }

    public close(): void {
        this.dialogRef.close(false);
    }

    public updateActionArgs() {
        this.resultText = "";
        this.argumentsValues = {};
        if (this.selectedAction === undefined) {
            return;
        }
        this.selectedAction.arguments.forEach(arg => {
            this.argumentsValues[arg.name] = arg.defaultValue;
        });
    }

    private checkValidation(value: string, pattern: string): boolean {
        if (value === "") {
            return false;
        }
        const regex = new RegExp(pattern);
        return regex.test(value);
    }
}
