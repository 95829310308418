import { Injectable } from '@angular/core';
import {Load, LoadPrediction} from "@io-elon-common/frontend-api";

@Injectable({
    providedIn: 'root'
})
export class LoadUtilsService {
    constructor() { }

    public getCurrentPwr(load: Load): number {
        const tst = new Date().valueOf();
        const oldPredictions = load.prediction.filter(p=>p.start <= tst);
        if (oldPredictions.length === 0) {
            if (load.prediction.length > 0) {
                return this.getPredictionSum(load.prediction[0]);
            } else {
                return -1;
            }
        }
        const currentPrediction = oldPredictions[oldPredictions.length-1];
        return this.getPredictionSum(currentPrediction);
    }

    public getMaxPwr(load: Load): number {
        return Math.max(...load.prediction.map(p=> {
            let sum = 0;
            if (p.l1 !== undefined) {
                sum += p.l1;
            }
            if (p.l2 !== undefined) {
                sum += p.l2;
            }
            if (p.l3 !== undefined) {
                sum += p.l3;
            }
            return sum;
        }));
    }

    private getPredictionSum(prediction: LoadPrediction) {
        let sum = 0;
        if (prediction.l1 !== undefined) {
            sum += prediction.l1;
        }
        if (prediction.l2 !== undefined) {
            sum += prediction.l2;
        }
        if (prediction.l3 !== undefined) {
            sum += prediction.l3;
        }
        return sum/1000;
    }
}
