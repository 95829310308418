<div style="height: 240px" class="d-flex flex-column justify-content-start align-items-stretch gap-2">
    <div class="d-flex flex-row justify-content-between align-items-center">
        <h2>{{config.name}}&nbsp;</h2>
        <div class="me-2">
            <button (click)="edit()" mat-icon-button matTooltip="Bearbeiten"><mat-icon>edit</mat-icon></button>
            <button (click)="copy()" mat-icon-button matTooltip="Kopieren"><mat-icon>content_copy</mat-icon></button>
            <button (click)="delete()" mat-icon-button matTooltip="Löschen"><mat-icon>delete</mat-icon></button>
        </div>
    </div>
    <div>
        <app-algo-v2-config-graphs *ngIf="config.strategy === 'v2_soft_int'" [config]="config"></app-algo-v2-config-graphs>
        <img *ngIf="config.strategy === 'JENS_ALGO' && config.params.fastCharge === 'true'" src="assets/img/algo/dynamischFast.png" alt="Dynamischer Algorithmus">
        <img *ngIf="config.strategy === 'JENS_ALGO' && config.params.fastCharge !== 'true'" src="assets/img/algo/dynamisch.png" alt="Dynamischer Algorithmus">
        <img *ngIf="config.strategy === 'JOHAN_ALGO'" src="assets/img/algo/statisch.png" alt="Statischer Algo">
    </div>
</div>
