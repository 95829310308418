import {Pipe, PipeTransform} from '@angular/core';
import {PlugState, PlugStateLabel} from './plug-state.enum';

@Pipe({
  name: 'plugText'
})
export class PlugTextPipe implements PipeTransform {
    public constructor() {
    }

    transform(plugState:PlugState): string {
        return PlugStateLabel[plugState];
    }
}
