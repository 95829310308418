import {Component, ViewEncapsulation} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
import {FRONTEND_API_VERSION} from '@io-elon-common/frontend-api';

export const ALL_ICONS: string[] = [];
export const API_VERSION = FRONTEND_API_VERSION;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    title = 'IO-ELON';

    constructor(private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                private toastr: ToastrService) {

        this.toastr.toastrConfig.positionClass = 'toast-bottom-right';
        this.toastr.toastrConfig.closeButton = true;
        this.toastr.toastrConfig.preventDuplicates = true;
        this.toastr.toastrConfig.countDuplicates = true;
        this.toastr.toastrConfig.resetTimeoutOnDuplicate = true;
        this.toastr.toastrConfig.enableHtml = true;

        const imgFolderContent = [
            'allowed_1.svg',
            'allowed_2.svg',
            'angle.svg',
            'battery_0.svg',
            'battery_1.svg',
            'battery_2.svg',
            'battery_3.svg',
            'battery_4.svg',
            'battery_health.svg',
            'booking.svg',
            'bulk_operations.svg',
            'car.svg',
            'charging_1.svg',
            'charging_2.svg',
            'chargingstation_info_2_blue.svg',
            'chargingstation_info_2.svg',
            'chargingstation_info.svg',
            'chargingstation_new.svg',
            'clock.svg',
            'crgcar.svg',
            'desktop_mac-black.svg',
            'e-truck.png',
            'fleet_location.svg',
            'graphic.svg',
            'height.svg',
            'help.svg',
            'icon_iodynamics.png',
            'kosten.svg',
            'ladestation.svg',
            'logout.svg',
            'maximum.svg',
            'notification.svg',
            'orientation.svg',
            'onroad_1.svg',
            'onroad_2.svg',
            'parking_1.svg',
            'parking_2.svg',
            'plug_connect_blue.svg',
            'plug_connect_grey.svg',
            'plug_connect.svg',
            'plug_disconnect_blue.svg',
            'plug_disconnect_grey.svg',
            'plug_disconnect_red.svg',
            'plug_disconnect.svg',
            'plug_energy_blue.svg',
            'plug_energy_grey.svg',
            'plug_energy.svg',
            'plugged_1.svg',
            'plugged_2.svg',
            'plug_pause_blue.svg',
            'plug_pause_grey.svg',
            'plug_pause.svg',
            'plug.svg',
            'plug_unknown_blue.svg',
            'plug_unknown_grey.svg',
            'plug_unknown_lightgrey.svg',
            'plug_unknown.svg',
            'plug_sequence.svg',
            'powerplug.svg',
            'power.svg',
            'profil.svg',
            'search.svg',
            'settings.svg',
            'temperature.svg',
            'truck.svg',
            'van.svg',
            'verwaltung.svg',
            'static.svg',
            'tesla.svg',
            'free2move.svg',
            'free2moveColor.svg',
            'car_control.svg',
            'evse_attention.svg',
            'evse_broken.svg',
            'evse_working.svg',
            'evse_maintenance.svg',
        ];

        const svgs = imgFolderContent.filter(filename => filename.endsWith('.svg'));
        const iconNames = svgs.map(filename => filename.substr(0, filename.length - 4));
        const iconPathes = svgs.map(filename => '../../assets/img/' + filename);

        // Nicht geil, aber es funktioniert
        if (ALL_ICONS.length === 0) {
            ALL_ICONS.push(...iconNames);
        }

        for (let i = 0; i < iconNames.length; i++) {
            this.matIconRegistry.addSvgIcon(
                iconNames[i],
                this.domSanitizer.bypassSecurityTrustResourceUrl(iconPathes[i]));
        }
    }
}
