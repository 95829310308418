import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {Evse, Fleet, Load, PowerSupply, SolarSystem} from '@io-elon-common/frontend-api';
import { FleetService } from '../../../vehicle/service/fleet.service';
import { ToastrService } from 'ngx-toastr';
import { BasisService } from '../../service/basis.service';
import { PowerSupplyService } from '../../service/power-supply.service';
import { SolarSystemService } from '../../../solar/service/solar-system.service';
import { EvseService } from '../../../evse/service/evse.service';
import { LoadService } from 'src/app/modules/loads/service/load.service';
import {MeterService} from "../../../meter/service/meter.service";

@Component({
    selector: 'app-basis-details-view',
    templateUrl: './basis-details-view.component.html',
    styleUrls: ['./basis-details-view.component.scss']
})
export class BasisDetailsViewComponent implements OnInit, OnDestroy {

    public fleet!: BehaviorSubject<Fleet | undefined>
    public solarSystems!: BehaviorSubject<SolarSystem[] | undefined>
    public evses!: BehaviorSubject<Evse[] | undefined>
    public loads!: BehaviorSubject<Load[] | undefined>


    private fleetIdSubscription?: Subscription;

    public error = '';

    constructor(
        private readonly fleetService: FleetService,
        private readonly toastr: ToastrService,
        private readonly basisService: BasisService,
        private readonly powerSupplyService: PowerSupplyService,
        private readonly solarSystemService: SolarSystemService,
        private readonly evseService: EvseService,
        private readonly loadService: LoadService,
        private readonly meterService: MeterService,
    ) {
    }

    ngOnInit(): void {
        this.evses = this.evseService.getAll();
        this.solarSystems = this.solarSystemService.getAll();
        this.loads = this.loadService.getAll();
        this.fleetIdSubscription = this.fleetService.selectedFleet.subscribe(sf => {
            if (sf !== undefined) {
                this.fleet = this.fleetService.get(sf);
            } else {
                this.error = "Es wurde keine Flotte ausgewählt."
            }
        })
    }

    ngOnDestroy(): void {
        if (this.fleetIdSubscription) {
            this.fleetIdSubscription.unsubscribe();
        }
    }

    public solarSystemToPowerSupply(panels: SolarSystem[], powerSupply: PowerSupply): SolarSystem[] {
        return panels.filter(p => p.powerSupplyId === powerSupply.id);
    }

    public get basePosition(): { lat: number, lng: number } | undefined {
        const base = this.fleet.getValue()?.base;
        if (!base) {
            return undefined;
        }
        return {
            lat: base.lat,
            lng: base.lon
        }
    }

    public trackPowerSupplyBy(index: number, ps: PowerSupply): number {
        return ps.id;
    }

    public async handleEditBasis(): Promise<void> {
        const basis = this.fleet.getValue()?.base;
        if (!basis) {
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        await this.basisService.showEditDialog(basis);
    }

    public async handleEditFleet(): Promise<void> {
        const fleet = this.fleet.getValue();
        if (!fleet) {
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        await this.fleetService.showEditDialog(fleet);
    }

    public async handleNewBasis(): Promise<void> {
        await this.basisService.showNewDialog();
    }

    public async handleEditPowerSupply(ps: PowerSupply): Promise<void> {
        const basis = this.fleet.getValue()?.base;
        if (!basis) {
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        await this.powerSupplyService.showEditDialog(ps, basis);
    }

    public async handleNewPowerSupply(): Promise<void> {
        const basis = this.fleet.getValue()?.base;
        if (!basis) {
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        await this.powerSupplyService.showNewDialog(basis);
    }

    public async handleNewSolarSystem(): Promise<void> {
        const powerSupplies = this.fleet.getValue()?.base.powerSupplies;
        if (powerSupplies === undefined) {
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        this.meterService.getAllPromise().then(meters => {
            if (meters === undefined) {
                this.toastr.warning("Fehler beim laden der möglichen Smartmeters");
                return;
            }
            const basis = this.fleet.getValue()?.base;
            this.solarSystemService.showNewDialog({
                possiblePowerSupplys: powerSupplies.filter(ps => ps.baseId === basis?.id),
                possibleMeters: meters.filter(meter => meter.basis?.id === basis?.id),
                basisId: basis?.id
            });
        })
    }

    public async handleDeletePowerSupply(ps: PowerSupply): Promise<void> {
        await this.powerSupplyService.showDeleteDialog(ps, {});
    }

    public async handleEditSolarSystem(sp: SolarSystem): Promise<void> {
        const powerSupplies = this.fleet.getValue()?.base.powerSupplies;
        if (powerSupplies === undefined) {
            this.toastr.warning("Keine Basis ausgewählt");
            return;
        }
        this.meterService.getAllPromise().then(meters => {
            if (meters === undefined) {
                this.toastr.warning("Fehler beim laden der möglichen Smartmeters");
                return;
            }
            this.solarSystemService.showEditDialog(sp, {
                possiblePowerSupplys: powerSupplies.filter(ps => ps.baseId === sp.basis?.id),
                possibleMeters: meters.filter(meter => meter.basis?.id === sp.basis?.id),
                basisId: sp.basis?.id
            });
        });
    }
    public async handleDeleteSolarSystem(sp: SolarSystem): Promise<void> {
        await this.solarSystemService.showDeleteDialog(sp, {});
    }

    public async handleResetPeaks(ps: PowerSupply): Promise<void> {
        await this.powerSupplyService.resetPeaks(ps.id);
    }
}
