<table mat-table [trackBy]="trackById" [dataSource]="dataSource" class="full-width-table" matSort (matSortChange)="setSort($event)" [matSortActive]="sort.active" [matSortDirection]="sort.direction" matSortDisableClear>

    <!-- Icon Column -->
    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef class="col-icon">Fahrzeug</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            <div class="btn-car me-2">
                <mat-icon class="iblack" svgIcon="car"></mat-icon>
            </div>
        </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-name"></th>
        <td mat-cell *matCellDef="let element" class="col-name">
            <span *ngIf="!element.canView">{{element.name}}</span>
            <app-vehicle-link [vehicle]="element"></app-vehicle-link><br>
            <span class="light">{{element.localId > 0 ? element.localId : ""}} {{element.numberPlate}}</span>
        </td>
    </ng-container>


    <!-- Plug icon -->
    <ng-container matColumnDef="plugIcon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-plug">Stecker</th>
        <td mat-cell *matCellDef="let vehicle" class="col-plug">
            <mat-icon class="iblack"
                      [svgIcon]=   "vehicle | plugStateVehicle: getEvseByVehicle(vehicle, evses)?.liveData.evseAlw?.val | plugIcon"
                      inline></mat-icon>
            <mat-hint *ngIf="getPwrMsg(vehicle) as pwr" [class.targetVisible]="targetVisible" (click)="toggleTargetVisible()">
                {{pwr.live}}
                <span class="target">
                    <span *ngIf="pwr.live"> / </span>
                    {{pwr.target}}
                </span>
                {{pwr.unit}}
            </mat-hint>
            <div *ngIf="getChargePlanInfo(vehicle) as pv">
                <!--{{pv | json}}-->
            </div>
        </td>
    </ng-container>


    <!-- Ladepunkt Column -->
    <ng-container matColumnDef="evse">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-evse">Ladepunkt</th>
        <td mat-cell *matCellDef="let element" class="col-evse">
            <span *ngIf="element.evse">
                <ng-container *ngIf="canViewEvse(element.evse); else noLink">
                    <app-evse-link [evse] = "element.evse"></app-evse-link>
                </ng-container>
                <ng-template #noLink>
                    {{element.evse | evseName}}
                </ng-template>
            </span>
            <span *ngIf="!element.evse && element.liveData.plugged?.val">Fremde Station</span>
            <i class="fas fa-list-dots assign-menu" *ngIf="isDev" [matMenuTriggerFor]="assignOptions" matTooltip="Assign (dev Only)"></i>
            <mat-menu #assignOptions="matMenu">
                <button mat-menu-item (click)="assign(element, undefined)">
                    <mat-icon svgIcon="plug_disconnect_grey"></mat-icon>
                    <span>Unassign</span>
                </button>
                <button mat-menu-item [matMenuTriggerFor]="evseMenu">
                    <mat-icon svgIcon="plug_connect_grey"></mat-icon>
                    <span>Assign</span>
                </button>
            </mat-menu>
            <mat-menu #evseMenu="matMenu">
                <ng-template matMenuContent>
                    <button mat-menu-item *ngFor="let e of evses; trackBy:trackById" (click)="assign(element, e)">
                        {{e.id}} - {{e.name}}
                        (<app-vehicle-link *ngIf="e.liveData.vehicle" [vehicle]="e.liveData.vehicle"></app-vehicle-link>)
                    </button>
                </ng-template>
            </mat-menu>
        </td>
    </ng-container>

    <!-- Algo State Column -->
    <ng-container matColumnDef="algoData">
        <th mat-header-cell *matHeaderCellDef class="col-algo">Ladeplan</th>
        <td mat-cell *matCellDef="let element" class="col-algo">
            <div class="d-flex flex-column gap-2 justify-content-between align-items-start">
                <ng-container *ngIf="getChargePlanInfo(element) as pv; else noPv">
                    <div [matTooltip]="getTooltip(pv)" matTooltipClass="pre-tooltip">
                        <i [class]="getIcon(pv.reasonType)"></i>
                        <span>{{(element | plugStateVehicle: getEvseByVehicle(element, evses)?.liveData.evseAlw?.val | plugText)}}</span>
                        <ng-container *ngIf="getEvseByVehicle(element, evses) as evse">
                            <ng-container *ngIf="evse.liveData.evseDetectedMaxAmps as maxAmps">
                                <span *ngIf="maxAmps.val < evse.maxI" [matTooltip]="'Begrenzt auf ' + maxAmps.val + 'A, da das Fahrzeug langsam geladen hat.'">&nbsp;(Begrenzt)</span>
                            </ng-container>
                        </ng-container>
                    </div>
                    <span class="light" [innerHTML]="getText(pv.reasonType)" [matTooltip]="getTooltip(pv)" matTooltipClass="pre-tooltip"></span>
                </ng-container>
                <ng-template #noPv>
                    <ng-container>
                        <div matTooltip="Kein Plan für diese Fahrzeug erstellt">
                            <i [class]="getIcon('NOT_CALCULATED')"></i>
                            <span>{{(element | plugStateVehicle: getEvseByVehicle(element, evses)?.liveData.evseAlw?.val | plugText)}}</span>
                        </div>
                        <span class="light" [innerHTML]="getText('NOT_CALCULATED')"></span>
                    </ng-container>
                </ng-template>
            </div>
        </td>
    </ng-container>

    <!-- Datenquelle Column -->
    <ng-container matColumnDef="sourceDesc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-source"> Datenquelle</th>
        <td mat-cell *matCellDef="let element" class="col-source">
            <div class="d-flex flex-column gap-1 align-items-start">
                <span>{{element.dataSource?.displayName}}</span>
                <span class="light" [matTooltip]="getAgeDetails(element)" [matTooltipClass]="'pre-tooltip'" [matTooltipPosition]="'right'">{{getAge(element)}}</span>
            </div>
        </td>
    </ng-container>

    <!-- Reichweite Column -->
    <ng-container matColumnDef="range">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-range"> Reichweite</th>
        <td mat-cell *matCellDef="let element" class="col-range">
            <span>{{element.liveData.rangeNow?.val.toFixed(0) || "--" }}</span> / <span>{{element.liveData.rangeFull?.val.toFixed(0) || "--"}}</span> km
        </td>
    </ng-container>

    <!-- Batteriegesundheit Column -->
    <ng-container matColumnDef="soh">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-health"> Batteriegesundheit</th>
        <td mat-cell *matCellDef="let element" class="col-health">
            <span>{{element.liveData.soh?.val || "--"}}</span> %
        </td>
    </ng-container>

    <!-- Ladestand Column -->
    <ng-container matColumnDef="stateOfCharge">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col-charge"> Ladestand</th>
        <td mat-cell *matCellDef="let element" class="col-charge">
            <mat-icon class="igray" [svgIcon]="getBattery(element) | BatteryIcon"></mat-icon>
            <div style="display: inline-block">
                <span class="soc">{{getBattery(element)}} %</span>
                <div class="mat-caption" style="display: inline-block">
                    <div>
                        <ng-container *ngIf="element.liveData.targetSocReachedTime">
                            <span *ngIf="!isTimeInPast(element.liveData.targetSocReachedTime) && !isEstimatedSocUTargetSocUClose(element)" [matTooltip]="'Ladestand erreicht: ' + (element.liveData.targetSocReachedTime | date) + ' ' + (element.liveData.targetSocReachedTime | date: 'shortTime')">
                                <mat-icon style="transform: scale(0.5) translate(7px, 3px)">access_time</mat-icon>
                                {{element.liveData.targetSocReachedTime | date:'shortTime'}}
                            </span>
                            <span *ngIf="isEstimatedSocUTargetSocUClose(element)">
                                <mat-icon style="transform: scale(0.5) translate(7px, 3px)">done</mat-icon>
                                Bereit
                            </span>
                        </ng-container>
                        <br/>
                        {{getBatteryTarget(element)}}
                    </div>
                </div>
            </div>
        </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="col-status"> Buchungsstatus</th>
        <td mat-cell *matCellDef="let element" class="col-status" (click)="openBookingDialog(element)">
            <div *ngIf="getReservationStatus(element) as status" class="vehicle-status" [style]="'color: ' + status.color + ';'">
                <div>{{status.msg}}</div>
                <span class="light">{{status.msg2}}</span>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="col-actions"></th>
        <td mat-cell *matCellDef="let element" class="col-actions">
            <ng-container *ngFor="let notification of activeNotifications; trackBy:trackById">
                <ng-container *ngIf="notification.vehicle === element.id">
                    <app-notification [notification]="notification" [headerName]="element.name"></app-notification>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let notification of mutedNotifications; trackBy:trackById">
                <ng-container *ngIf="notification.vehicle === element.id">
                    <app-notification [notification]="notification" [headerName]="element.name"></app-notification>
                </ng-container>
            </ng-container>

            <button mat-icon-button matTooltip="Mit Tronity verbinden" *ngIf="hasTronityButtons(element)" (click)="tronityConnect(element)">
                <mat-icon
                    class="iblack">
                    add_to_photos
                </mat-icon>
            </button>
            <button mat-icon-button matTooltip="Aufwecken" *ngIf="hasTeslaButtons(element)" (click)="wakeUp(element)">
                <mat-icon
                    class="iblack">
                    settings_remote
                </mat-icon>
            </button>
            <button mat-icon-button matTooltip="Daten sofort laden" *ngIf="hasTeslaButtons(element)" (click)="pollNow(element)">
                <mat-icon
                    class="iblack">
                    sync
                </mat-icon>
            </button>
            <ng-container *ngIf="canStop(element)">
                <button mat-icon-button (click)="stop(element)" *ngIf="!isStopped(element)" matTooltip="Ladevorgang stoppen">
                    <mat-icon class="iblack">report</mat-icon>
                </button>

                <ng-container *ngIf="isStopped(element)">
                    <button mat-icon-button *ngIf="!basis.allowContinue" matTooltip="Ladevorgang wurde gestoppt.">
                        <mat-icon class="iblack">not_interested</mat-icon>
                    </button>
                    <button mat-icon-button (click)="continue(element)" *ngIf="basis.allowContinue" matTooltip="Ladevorgang fortsetzen" >
                        <mat-icon class="iblack">play_arrow</mat-icon>
                    </button>
                </ng-container>
            </ng-container>
            <button mat-icon-button *ngIf="element.canEdit" (click)="edit(element)" matTooltip="Bearbeiten">
                <mat-icon
                    class="iblack">
                    edit
                </mat-icon>
            </button>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"
        (click)="$event.stopPropagation();$event ? selectRow(row) : null"
        [ngClass]="{selected: selectedIndex === row.id, outdated: isOutdated(row)}">
    </tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
