import {Component, Input} from '@angular/core';
import {DynamicLoadType, Load, PowerSupply} from '@io-elon-common/frontend-api';
import {ToastrService} from 'ngx-toastr';
import { LoadService } from 'src/app/modules/loads/service/load.service';
import { MeterService } from 'src/app/modules/meter/service/meter.service';
import {LoadUtilsService} from "../../../../../shared/helper/load-utils.service";

@Component({
    selector: 'app-load-tree-view-node',
    templateUrl: './load-tree-view-node.component.html',
    styleUrls: ['./load-tree-view-node.component.scss']
})
export class LoadTreeViewNodeComponent {

    @Input()
    public load!: Load;

    @Input()
    powerSupplies!: PowerSupply[];

    private peak = -1;

    public constructor(
        private readonly toastr: ToastrService,
        private readonly loadService: LoadService,
        private readonly meterService: MeterService,
        private readonly loadUtils: LoadUtilsService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        if (this.isMeterBased()) {
            const meterId: number = this.load.meterId as number;
            const meterFields = await this.meterService.getFields(meterId);
            if (meterFields.fields.find(field=> field.name === "Peak") !== undefined) {
                const tst = new Date().valueOf();
                const values = await this.meterService.getHistory(meterId, tst-1000*10, tst);
                if (values.data.peak !== undefined)
                    this.peak = values.data.peak[values.data.peak.length-1].max;
            }
        }
    }

    public isMeterBased(): boolean {
        return this.load.type === DynamicLoadType.MeterBasedDynamicLoad && this.load.meterId!==undefined;
    }

    public getPwr(): number {
        return this.loadUtils.getCurrentPwr(this.load);
    }

    public getMaxPwr(): number {
        const maxPrediction = this.loadUtils.getMaxPwr(this.load);
        this.peak = Math.max(this.peak, maxPrediction/1000);
        return this.peak;
    }

    public async handleEditLoad(load: Load): Promise<void> {
        await this.loadService.showEditDialog(load, this.powerSupplies);
    }

    public async handleDeleteLoad(load: Load): Promise<void> {
        await this.loadService.showDeleteDialog(load, {});
    }
}
