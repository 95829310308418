import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Moment} from 'moment';
import {DateRange} from '@angular/material/datepicker';
import {FormControl} from '@angular/forms';
import * as moment from 'moment';

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

@Component({
    selector: 'app-time-range-picker',
    templateUrl: './time-range-picker.component.html',
    styleUrls: ['./time-range-picker.component.scss']
})
export class TimeRangePickerComponent implements OnInit {
    @Output() readonly rangeChange = new EventEmitter<DateRange<Moment>>();
    @Output() readonly asapChange = new EventEmitter<boolean>();
    @Output() readonly useDurationChange = new EventEmitter<boolean>();

    @Input() min!: Moment;
    @Input() range!: DateRange<Moment>;
    @Input() asapAllowed = false;
    @Input() asapDisabled = false;
    @Input() asap!: boolean;
    @Input() useDuration = false;

    public _sameDay = true;

    public dateStart!: FormControl<moment.Moment | null>;
    public dateEnd!: FormControl<moment.Moment | null>;

    private defaultStartTime!: number;

    constructor() {

    }

    ngOnInit(): void {
        const start = (this.range.start || moment()).valueOf();
        const end = this.range.end?.valueOf() || start + 4 * HOUR;
        this.dateStart = new FormControl(moment(start - start % DAY));
        this.dateEnd = new FormControl(moment(end - end % DAY));

        this.defaultStartTime = start;
    }

    public toggleUseDuration(): void {
        this.useDuration = !this.useDuration;
        this.useDurationChange.emit(this.useDuration);
    }

    public asapClicked(): void {
        if(this.asap) {
            this.millisStart = Date.now() + this.utcOffsetMillis;
            // this.dateStart.setValue(moment().startOf("day"));
            // this.hourStart = moment().add(this.utcOffsetMillis, 'milliseconds').hour();
            // this.minuteStart = moment().add(this.utcOffsetMillis, 'milliseconds').minute();
        } else {
            this.millisStart = this.defaultStartTime;
            // const start = this.defaultStartTime;
            // this.dateStart.setValue(moment(start).startOf('day'));
            // this.hourStart = moment(start).hour();
            // this.minuteStart = moment(start).minute();
        }
        this.asapChange.emit(this.asap);
    }

    public startDateChange(): void {
        this.dateStartMillis = this.dateStart.value!.valueOf();
    }

    public endDateChange(): void {
        this.dateEndMillis = this.dateEnd.value!.valueOf();
    }

    public endTimeChange(val: string): void {
        this.timeEnd = val;
    }

    public startTimeChange(val: string): void {
        this.timeStart = val;
    }

    public get utcOffsetMillis(): number {
        return moment().utcOffset() * MINUTE
    }

    public get millisStart(): number {
        return (this.range.start as Moment).valueOf() + this.utcOffsetMillis;
    }

    public set millisStart(val: number) {
        val = val - this.utcOffsetMillis;
        if(this.useDuration){
            const start = this.range.start?.valueOf();
            const end = this.range.end?.valueOf();
            if(!start || !end){
                console.error("Invalid date range", start, end);
                return;
            }
            const delta = val - start;
            const newEnd = end + delta;
            this.range = new DateRange<Moment>(moment(val), moment(newEnd));
            this.dateStart.setValue(moment(val).clone().startOf('day'));
            this.dateEnd.setValue(moment(newEnd).clone().startOf('day'));
        } else {
            const newStart = moment(val);
            if(newStart.isAfter(this.range.end)){
                this.range = new DateRange<Moment>(newStart, newStart)
                this.dateStart.setValue(moment(newStart).clone().startOf('day'));
                this.dateEnd.setValue(moment(newStart).clone().startOf('day'));
            } else {
                this.range = new DateRange<Moment>(newStart, this.range.end);
                this.dateStart.setValue(moment(newStart).clone().startOf('day'));
            }
        }
        this.rangeChange.emit(this.range);
    }

    public get minuteStartMillis(): number {
        return this.millisStart % HOUR;
    }

    public set minuteStartMillis(val: number) {
        this.millisStart = this.millisStart - this.minuteStartMillis + val;
    }

    public get hourStartMillis(): number {
        return this.millisStart % DAY - this.minuteStartMillis;
    }

    public set hourStartMillis(val: number) {
        this.millisStart = this.millisStart - this.hourStartMillis + val;
    }

    public get dateStartMillis(): number {
        return this.millisStart - this.hourStartMillis - this.minuteStartMillis;
    }

    public set dateStartMillis(val: number) {
        this.millisStart = this.millisStart - this.dateStartMillis + val;
    }

    public get minuteStart(): number {
        return Math.floor(this.minuteStartMillis / MINUTE)
    }

    public set minuteStart(val: number) {
        this.minuteStartMillis = val * MINUTE;
    }

    public get hourStart(): number {
        return this.hourStartMillis / HOUR;
    }

    public set hourStart(val: number) {
        this.hourStartMillis = val * HOUR
    }

    public get timeStart(): string {
        return this.hourStart + ":" + this.minuteStart;
    }

    public set timeStart(val: string) {
        const split = val.split(':');
        this.hourStart = parseInt(split[0], 10);
        this.minuteStart = parseInt(split[1], 10);
    }

    public get millisEnd(): number {
        return (this.range.end as Moment).valueOf() + this.utcOffsetMillis;
    }

    public set millisEnd(val: number) {
        val = val - this.utcOffsetMillis;
        const start = this.range.start;
        const newEnd = moment(val);
        if(!start) {
            console.error("Invalid range", start, newEnd);
            return;
        }
        if(newEnd.isBefore(start)) {
            this.range = new DateRange<Moment>(newEnd, newEnd);
            this.dateStart.setValue(start.clone().startOf('day'));
            this.dateEnd.setValue(newEnd.clone().startOf('day'));
        } else {
            this.range = new DateRange<Moment>(start, newEnd);
            this.dateStart.setValue(start.clone().startOf('day'));
            this.dateEnd.setValue(newEnd.clone().startOf('day'));
        }
        this.rangeChange.emit(this.range);
    }

    public get minuteEndMillis(): number {
        return this.millisEnd % HOUR;
    }

    public set minuteEndMillis(val: number) {
        this.millisEnd = this.millisEnd - this.minuteEndMillis + val;
    }

    public get hourEndMillis(): number {
        return this.millisEnd % DAY - this.minuteEndMillis;
    }

    public set hourEndMillis(val: number) {
        this.millisEnd = this.millisEnd - this.hourEndMillis + val;
    }

    public get dateEndMillis(): number {
        return this.millisEnd - this.hourEndMillis - this.minuteEndMillis;
    }

    public set dateEndMillis(val: number) {
        this.millisEnd = this.millisEnd - this.dateEndMillis + val;
    }

    public get minuteEnd(): number {
        return Math.floor(this.minuteEndMillis / MINUTE)
    }

    public set minuteEnd(val: number) {
        this.minuteEndMillis = val * MINUTE;
    }

    public get hourEnd(): number {
        return this.hourEndMillis / HOUR;
    }

    public set hourEnd(val: number) {
        this.hourEndMillis = val * HOUR
    }

    public get timeEnd(): string {
        return this.hourEnd + ":" + this.minuteEnd;
    }

    public set timeEnd(val: string) {
        const split = val.split(':');
        this.hourEnd = parseInt(split[0], 10);
        this.minuteEnd = parseInt(split[1], 10);
    }

    public get durationMillis(): number {
        return this.millisEnd - this.millisStart;
    }

    public set durationMillis(val: number) {
        this.millisEnd = this.millisStart + val;
    }

    public get durationHours(): number {
        return this.durationMillis / HOUR;
    }

    public set durationHours(val: number) {
        this.durationMillis = val * HOUR;
    }

    public get otherDayReturn(): boolean {
        return !this._sameDay || this.dateStartMillis !== this.dateEndMillis;
    }

    public set otherDayReturn(val: boolean) {
        this._sameDay = !val;
    }
}
