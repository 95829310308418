<mat-dialog-content *ngIf="((evse | async)?.liveData.validationResult) as validations">
    <div class="dialog-head">
        <h2 class="mat-h2">Validierung Ergebnisse</h2>
        <mat-icon class="help-icon" #helpIcon>help</mat-icon>
        <mat-icon class="close-icon" (click)="close()">close</mat-icon>
        <span><app-evse-link [evseId]="evseId"></app-evse-link></span>
    </div>
    <app-help-box [helpIcon]="helpIcon._elementRef" key="VALIDATE_EVSE_POPUP">
        <p>
            Um Fehler und unerwartetes Verhalten zu vermeiden, prüft IO-ELON regelmäßig die Einstellungen der Ladepunkte.
            Hier können sie die Ergebnisse sehen. Wenn es bei der Prüfung ein Problem gibt, wird der Ladepunkt in der
            Übersicht markiert, damit die Konfiguration manuell geprüft werden kann.
        </p>
        <p>
            In diesem Dialog haben Sie die Möglichkeit, Konfigurationsprobleme zu analysieren, und sie für künftige
            Prüfungen als Ignoriert zu markieren, indem Sie diese auf eine Whitelist setzten. Alternativ können Sie
            einen von IO-ELON Vorgeschlagenen Wert in die Konfiguration des Ladepunktes schreiben.
        </p>
    </app-help-box>

    <div class="accordion" id="results">
        <ng-container *ngFor="let val of validations; let i = index; trackBy:getKey">
            <div class="accordion-item">
                <button class="accordion-header accordion-button d-flex gap-2 collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#rule_' + i">
                    <div class="flex-grow-0 status {{val.validationResult}}" [matTooltip]="validationResultToDisplayName(val.validationResult)"></div>
                    <div class="flex-grow-1"><b>{{val.ruleDisplayName}} </b> <mat-hint>({{val.ruleKey}})</mat-hint></div>
                </button>
                <div class="accordion-collapse collapse p-2" data-bs-parent="#results" id="rule_{{i}}">
                    <div class="row">
                        <div class="col-12"><mat-hint>{{val.ruleDisplayDetails}}</mat-hint></div>
                    </div>
                    <div class="row">
                        <div class="col-3"><mat-hint>Validierungsstatus:</mat-hint><br>{{validationResultToDisplayName(val.validationResult)}}</div>
                        <div class="col-3"><mat-hint>Wert:</mat-hint><br><pre class="valuePre">{{val.validationVal}}</pre></div>
                        <div class="col-3"><mat-hint>Sollwert:</mat-hint><br><pre class="valuePre">{{val.ruleValidRange}}</pre></div>
                        <div class="col-3"><mat-hint>Empfohlen:</mat-hint><br><pre class="valuePre">{{val.ruleDefaultVal}}</pre></div>
                    </div>
                    <div class="row">
                        <div class="col-12"><mat-hint>Bemerkung:</mat-hint><br>{{getRemarkText(val)}}</div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-end" *ngIf="(whitelist | async) as whitelist; else noWhitelist">
                            <button mat-raised-button color="primary" class="mx-2" [disabled]="!isOnWhitelist(whitelist, val)" (click)="whitelistRemove(whitelist, val)">Nicht mehr ignorieren</button>
                            <button mat-raised-button color="primary" class="mx-2" [disabled]="isOnWhitelist(whitelist, val)" (click)="whitelistAdd(val)">Ignorieren</button>
                            <button *ngIf="val.fixMethod === FixMethodEnum.WriteToConfig" mat-raised-button color="primary" class="mx-2" [disabled]="val.ruleDefaultVal === val.validationVal" (click)="setDefault(val)" [matTooltip]="'Schreibe ' + val.ruleDefaultVal + ' in die Konfiguration des Ladepunktes'">Empfohlenen Wert setzen</button>
                        </div>
                        <ng-template #noWhitelist>
                            <div class="col-12 text-end">
                                <button mat-raised-button color="primary" class="mx-2" disabled>Von Whitelist entfernen</button>
                                <button mat-raised-button color="primary" class="mx-2" disabled>Zur Whitelist hinzufügen</button>
                                <button mat-raised-button color="primary" class="mx-2" (click)="setDefault(val)">Empfohlenen Wert setzen</button>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="buttons d-flex flex-row justify-content-end gap-2">
    <button
        mat-raised-button color="accent"
        type="submit"
        (click)="showDetails()"
        [disabled]="loadingActorDetails"
        class="details-button">
        Einzelheiten zum Ladepunkt
    </button>
    <button
        mat-raised-button color="accent"
        type="submit"
        (click)="revalidate()"
        [disabled]="loadingActorDetails"
        class="details-button">
        Erneut Validieren
    </button>
    <button
        mat-raised-button color="accent"
        type="submit"
        (click)="close()"
        class="close-button">
        Schließen
    </button>
</mat-dialog-actions>
