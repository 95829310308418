<div *ngIf="(evse | async) as evse; else loading">
    <ng-container *ngIf="(vehicles | async) as vehicles; else loading">
	    <div class="d-flex flex-column gap-3">
	        <app-boxes [boxes]="boxes"></app-boxes>
	        <div class="d-flex flex-row align-content-between w-100 widget-row gap-2">
	            <div class="flex-grow-1">
	                <img class="center" *ngIf="evse" src="/img/evses/{{evse.img}}" alt="Bild der Ladepunkt">
	            </div>
                <div class="flex-grow-1">
                    <div class="center" style="padding: 25%">
                        <div>
                            <mat-icon *ngIf="getHealthIcon(evse) != ''" svgIcon="{{getHealthIcon(evse)}}" class="evse-health-icon"></mat-icon>
                        </div>
                    </div>
                </div>
	            <div class="flex-grow-1">
	                <div class="center" *ngIf="evse">
	                    <mat-icon class="iblack mat-icon-evse" inline="true"
	                              [svgIcon]="evse | plugStateEvse | plugIcon"></mat-icon>
	                    <br>
	                    {{evse | plugStateEvse | plugText}}
	                </div>
	            </div>
	            <div class="flex-grow-1">
	                <div class="center">
	                    <app-radial-bar [value]="power" [label]="'Ladeleistung'" [max]="maxPower" [unit]="'kW'" [fractionDigits]="1"></app-radial-bar>
	                </div>
	            </div>
	            <div class="flex-grow-1">
	                <div class="center">
	                    <app-radial-bar [value]="i" [label]="'Ladestrom'" [max]="maxI" [unit]="'A'" [fractionDigits]="1"></app-radial-bar>
	                </div>
	            </div>
	        </div>
	        <div>
	            <mat-card appearance="outlined" class="graph-container d-relative">
	                <h2 class="m-auto">Verlauf</h2>
	                <div class="actions">
	                    <mat-checkbox class="reload-checkbox" #autoReload checked="checked">Automatisch neu laden</mat-checkbox>
	                    <app-graph-date-picker *ngIf="graph1" [graph]="graph1"></app-graph-date-picker>
						<app-graph-date-picker *ngIf="graph2" [graph]="graph2"></app-graph-date-picker>
                        <app-graph-help-icon [options]="['controlZoom', 'leftClickMove', 'shiftLeftClickZoom', 'doubleLeftClickReset']" [vAlign]="'middle'"></app-graph-help-icon>
	                </div>
					<mat-tab-group [(selectedIndex)]="selectedTab" (selectedTabChange)="setSelectedTab($event)">
						<mat-tab label="Übersicht (Leistung)">
							<ng-template matTabContent>
								<div style="height: 350px">
									<app-evse-history-graph #graph2 [evseId]="evse.id" [selectedUnit]="powerUnits.W" [(autoReload)]="autoReload.checked"></app-evse-history-graph>
								</div>
								<app-background-legend *ngIf="graph2" [matchingGraph]="graph2"></app-background-legend>
							</ng-template>
						</mat-tab>
						<mat-tab label="Übersicht (Strom)">
							<ng-template matTabContent>
								<div style="height: 350px">
									<app-evse-history-graph #graph1 [evseId]="evse.id" [selectedUnit]="powerUnits.A" [(autoReload)]="autoReload.checked"></app-evse-history-graph>
								</div>
								<app-background-legend *ngIf="graph1" [matchingGraph]="graph1"></app-background-legend>
							</ng-template>
						</mat-tab>
					</mat-tab-group>
	            </mat-card>
	        </div>
	        <div>
	            <app-evse-chargings-table class="with-background" [evseId]="evse.id"></app-evse-chargings-table>
	        </div>
            <div class="d-flex flex-row align-content-between align-items-stretch gap-3">
                <div class="flex-grow-1 with-background">
                    <app-data-table [obj]="evse" [keys]="evseLiveDataTableKeys" headline="Live Daten"></app-data-table>
                </div>
                <div class="flex-grow-1 d-flex flex-column gap-3">
                    <div class="with-background">
                        <app-data-table [obj]="evse" [keys]="evseConfigDataTableKeys" headline="Config Daten"></app-data-table>
                    </div>
                    <app-event-log-table class="with-background" [loadDataCb]="loadHistoryCb"></app-event-log-table>
                </div>
            </div>
	        <div class="d-flex flex-row justify-content-end gap-3">
                    <button mat-raised-button color="primary" type="submit" (click)="showLog()" *ngIf="supportsLog">
                        Protokoll
                    </button>
                    <button mat-raised-button color="primary" type="submit" matTooltip="Validierung" [disabled]="evse.liveData.validationResult.length === 0" (click)="handleValidation()">
                        Konfiguration Prüfung
                    </button>
                    <button [disabled]="!evse.canEdit" mat-raised-button color="primary" type="submit" (click)="actions()">
                        Aktionen
                    </button>
	                <button mat-raised-button color="primary" type="submit" (click)="details()">
                        Details
	                </button>
	                <button [disabled]="!evse.canEdit" mat-raised-button color="primary" type="submit" (click)="edit()">
	                    Bearbeiten
	                </button>
	                <button [disabled]="!evse.canDelete" mat-raised-button color="warn" type="submit" (click)="delete()">Löschen</button>
	        </div>
	    </div>
    </ng-container>
</div>
<ng-template #loading>
    Loading...
</ng-template>
