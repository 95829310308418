import Area = dygraphs.Area;
import Options = dygraphs.Options;
import Annotation = dygraphs.Annotation;
import LegendData = dygraphs.LegendData;
import Dygraph from 'dygraphs';
import {Directive, ElementRef, EventEmitter, OnDestroy} from '@angular/core';
import {InteractionModel} from './interaction-model';
import {EventLog, EventLogEntry, HistoryDataElement} from '@io-elon-common/frontend-api';
import { PowerUnits } from 'src/app/shared/helper/power-units';

// noinspection JSUnusedLocalSymbols
// declare const smoothPlotter: any;

const REQUEST_DELAY = 300; // ms
const POLL_DELAY = 5000; // 5 sekunden

export interface GraphRange {
    start: number;
    end: number;
}

export interface BackgroundLegend {
    name: string,
    visible: boolean,
    drawCallback: (canvas: CanvasRenderingContext2D, x: number, y: number, w: number, h: number) => void
}

export type HistoryCellData = Date | number | null | number[];

@Directive()
// tslint:disable-next-line:max-line-length directive-class-suffix
export abstract class AbstractHistoryGraph<T extends { data: Array<Array<HistoryCellData>>, events: Annotation[] }> implements OnDestroy {
    private static readonly HATCHED_SIZE = 5;
    private static readonly VOLTAGE = 235;
    protected data!: T;
    public dygraph!: Dygraph;
    public loading = 1;
    public abstract autoReload: boolean;
    public abstract autoReloadChange: EventEmitter<boolean>;
    private reloadTimeout: any;
    private pollTimeout: any;

    public start = this.defaultStart();
    public end = this.defaultEnd();

    public dateIndicatorText(): string {
        const start = this.dygraph?.xAxisRange()[0];
        if (start === undefined) return "";
        const date = new Date(start);
        const options: Intl.DateTimeFormatOptions = {year: '2-digit', month: '2-digit', day: '2-digit'}
        return date.toLocaleString("de", options);
    }

    public defaultStart(): number {
        // return new Date('2020-08-26T17:00:00.000Z').getTime();
        return Date.now() - 1000 * 60 * 60 * 48; // 2 Tage
    }

    public defaultEnd(): number {
        // return new Date('2020-08-27T05:00:00.000Z').getTime();
        return Date.now() + 1000 * 60 * 60 * 12; // 12 Stunden
    }

    public abstract getBackgroundLegend(): BackgroundLegend[];

    protected abstract loadData(start: number, end: number): Promise<T>;

    protected abstract getConfig(): Promise<Options>;

    protected abstract getMaxY2(): Promise<number>;

    public async updateToRange(start: number, end: number, resetZoom = false, skipTimeout = false): Promise<void> {
        clearTimeout(this.reloadTimeout);
        const reload = async () => {
            if(this.autoReload) {
                if((end < Date.now() - 1000 * 60) || (end - start > 1000 * 60 * 60 * 24 * 3)) { // 3 Tage
                    this.autoReload = false;
                    this.autoReloadChange.emit(false);
                }
            }
            try {
                this.loading++;
                this.data = await this.loadData(Math.floor(start), Math.ceil(end));
                this.start = start;
                this.end = end;
            } finally {
                this.loading--;
            }
            if (resetZoom) {
                this.dygraph.updateOptions({file: this.data.data as any, dateWindow: [start, end]});
            } else {
                this.dygraph.updateOptions({file: this.data.data as any});
            }
            this.resetPollTimeout();
        };
        if (skipTimeout) {
            await reload();
        } else {
            return new Promise((resolve, reject) => {
                this.reloadTimeout = setTimeout(async () => {
                    try {
                        await reload();
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                }, REQUEST_DELAY);
            });
        }
    }

    private resetPollTimeout() {
        clearTimeout(this.reloadTimeout);
        this.reloadTimeout = setTimeout(async () => {
            if (this.autoReload) {
                await this.updateToRange(this.dygraph.xAxisRange()[0], this.dygraph.xAxisRange()[1], false, false);
                this.resetPollTimeout();
            } else {
                this.resetPollTimeout();
            }
        }, POLL_DELAY);
    }

    private setDefaultIfMissing(obj: any, path: string, defaultVal: any) {
        const idx = path.indexOf('.');
        if (idx !== -1) {
            const localKey = path.substr(0, idx);
            const otherPath = path.substr(idx + 1);
            if (!obj[localKey]) {
                obj[localKey] = {};
            }
            this.setDefaultIfMissing(obj[localKey], otherPath, defaultVal);
        } else {
            if (obj[path] === undefined) {
                obj[path] = defaultVal;
            }
        }
    }

    protected async init(element: ElementRef): Promise<void> {
        try {
            this.data = await this.loadData(
                this.defaultStart(),
                this.defaultEnd());
        } finally {
            this.loading--;
        }
        const config = await this.getConfig();
        this.setDefaultIfMissing(config, 'strokeWidth', 2);
        this.setDefaultIfMissing(config, 'animatedZooms', true);
        this.setDefaultIfMissing(config, 'connectSeparatedPoints', true);
        this.setDefaultIfMissing(config, 'drawPoints', true);
        this.setDefaultIfMissing(config, 'legend', 'always');
        this.setDefaultIfMissing(config, 'pointSize', 3);
        this.setDefaultIfMissing(config, 'dateWindow', [this.defaultStart(), this.defaultEnd()]);
        // this.setDefaultIfMissing(config, "plotter", smoothPlotter);
        this.setDefaultIfMissing(config, 'zoomCallback', async (minX: number, maxX: number) => {
            await this.updateToRange(minX, maxX);
        });
        this.setDefaultIfMissing(config, 'interactionModel', new InteractionModel(this));
        this.setDefaultIfMissing(config, 'legendFormatter', (legendData: LegendData) => {
            if (!legendData.x) {
                return legendData.series
                    .filter(sd => sd.label !== "Events")
                    .map(sd => '' +
                        '<span class="legend-element">' +
                            '<span style="color: ' + sd.color + '">' + sd.dashHTML + '</span> ' + sd.labelHTML + '' +
                        '</span>')
                .join('\n');
            }

            return legendData.series
                .filter(sd => sd.label !== "Events")
                .map(sd => {
                    let val = "";
                    if(sd.y !== undefined && sd.y !== null && !isNaN(sd.y)) {
                        val = legendData.dygraph.getOption("axisLabelFormatter", sd.label)(sd.y);
                    }
                    return '<span class="legend-element"><span style="color: ' + sd.color + '">' + sd.dashHTML + '</span> ' + sd.labelHTML + ' ' + val + '</span>';
                })
            .join('\n');
        });

        this.dygraph = new Dygraph(element.nativeElement.querySelector(".line-graph"), this.data.data as any, config);
        this.dygraph.ready(() => {
            this.dygraph.setAnnotations(this.data.events);
        });
        this.resetPollTimeout();
    }

    protected mapToArray<U>(
        arr: U[],
        dateKey: keyof U,
        valKey: keyof U,
        minKey: keyof U | undefined,
        maxKey: keyof U | undefined,
        seriesIdx: number,
        seriesCount: number,
        converter?: (val: number) => number,
        maxConnectedDistance: number = Number.MAX_SAFE_INTEGER
    ): (HistoryCellData)[][] {
        if (arr.length === 0) {
            return [];
        }

        const base = new Array(seriesCount).fill(null);
        // @ts-ignore
        arr.sort((a, b) => a[dateKey] - b[dateKey]);

        let lastTst = arr[0][dateKey] as unknown as number;
        const result: (Date | number | null)[][] = [];
        arr.forEach(elem => {
            const tst = elem[dateKey] as unknown as number;
            const row: Array<HistoryCellData> = [new Date(tst)];
            row.push(...base);

            if (tst > lastTst + maxConnectedDistance) {
                const rowSeperator: Array<Date | number | null> = [new Date(lastTst + 1)];
                rowSeperator.push(...base);
                rowSeperator[seriesIdx + 1] = NaN;
                result.push(rowSeperator);
            }
            lastTst = tst;

            let val;
            if (converter) {
                if(minKey && maxKey) {
                    val = [converter(elem[minKey] as unknown as number),
                    converter(elem[valKey] as unknown as number),
                    converter(elem[maxKey] as unknown as number)];
                } else {
                    val = converter(elem[valKey] as unknown as number);
                }
            } else {
                if(minKey && maxKey) {
                    val = [Math.max(0, elem[minKey] as unknown as number),
                        Math.max(0, elem[valKey] as unknown as number),
                        Math.max(0, elem[maxKey] as unknown as number)];
                } else {
                    val = Math.max(0, elem[valKey] as unknown as number);
                }
            }
            row[seriesIdx + 1] = val
            result.push(row as any);
        });
        return result;
    }

    protected joinLines(sortedDataArray: Array<Array<HistoryCellData>>): Array<Array<HistoryCellData>> {
        if (sortedDataArray.length === 0) {
            return sortedDataArray;
        }
        return sortedDataArray.reduce((previousValue: Array<Array<HistoryCellData>>, currentValue: Array<HistoryCellData>) => {
            const lastElem = previousValue[previousValue.length - 1];
            if ((lastElem[0] as Date).getTime() === (currentValue[0] as Date).getTime()) {
                for (let i = 1; i < lastElem.length; i++) {
                    if (lastElem[i] === null) {
                        lastElem[i] = currentValue[i];
                    }
                }
                return previousValue;
            }
            previousValue.push(currentValue);
            return previousValue;
        }, [sortedDataArray[0]]);
    }

    protected chunkEvents(end: number, start: number, eventLog: EventLog): Array<EventLogEntry & {multiple?: boolean}> {
        const slotCount = 200;
        const slotSize = (end - start) / slotCount;
        let lastSlot = -1;
        return eventLog.events.sort((e1, e2) => e1.tst - e2.tst).reduce((previousValue, currentValue) => {
            const slot = Math.floor((end - currentValue.tst) / slotSize);
            if (slot !== lastSlot && slot <= slotCount && slot >= 0) {
                previousValue.push(currentValue);
                lastSlot = slot;
            } else if(previousValue.length > 0) {
                previousValue[previousValue.length -1].multiple = true;
            }
            return previousValue;
        }, [] as Array<EventLogEntry & {multiple?: boolean}>);
    }

    protected boolHistoryToAreas(data: { time: number, val: boolean }[]): GraphRange[] {
        data = data.sort((e1, e2) => e1.time - e2.time);

        const areas: GraphRange[] = [];
        let currentArea = {start: 0, end: 0};

        for (const line of data) {
            if (line.val && !currentArea.start) {
                currentArea.start = line.time;
            }
            if (!line.val && currentArea.start && !currentArea.end) {
                currentArea.end = line.time;
                areas.push(currentArea);
                currentArea = {start: 0, end: 0};
            }
        }
        if (currentArea.start) {
            currentArea.end = data[data.length - 1].time;
            areas.push(currentArea);
        }
        return areas;
    }

    protected drawReservationBox(
        canvas: CanvasRenderingContext2D,
        x: number,
        y: number,
        w: number,
        h: number,
        socHeight: number,
        flagX: number
    ) {
        if(flagX < x || flagX > x+w) {
            flagX = x;
        }

        canvas.beginPath();
        canvas.fillStyle = '#86d48b';
        canvas.fillRect(x, y, w, h);
        canvas.moveTo(flagX + 1, y);
        canvas.lineTo(flagX + 1, socHeight);
        canvas.strokeStyle = '#86d48b';
        canvas.stroke();
        canvas.closePath();
        canvas.fillRect(flagX, socHeight - 2, 6, 6);
    }

    protected drawNowMarker(
        canvas: CanvasRenderingContext2D,
        x: number,
        y: number,
        w: number,
        h: number,
    ): void {
        canvas.fillStyle = 'red';
        canvas.fillRect(x, y, w, h);
    }

    protected drawAreaRangesFilled(
        canvas: CanvasRenderingContext2D,
        area: Area,
        graph: Dygraph,
        ranges: GraphRange[],
        fillStyle: string
    ): void {
        canvas.fillStyle = fillStyle;
        ranges.forEach(slot => {
            const left = Math.max(graph.toDomXCoord(slot.start), area.x);
            const right = Math.min(graph.toDomXCoord(slot.end), area.x + area.w);
            this.drawRect(canvas, left, area.y, right - left, area.h, fillStyle);
        });
    }

    protected drawRect(canvas: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, color: string) {
        canvas.fillStyle = color;
        canvas.fillRect(x, y, w, h);
    }

    protected drawAreaRangesHatched(
        canvas: CanvasRenderingContext2D,
        area: Area,
        graph: Dygraph,
        ranges: GraphRange[],
        strokeStyle: string
    ): void {
        ranges.forEach(slot => {
            const left = graph.toDomXCoord(slot.start);
            const right = graph.toDomXCoord(slot.end);
            this.drawHatched(canvas, left, area.y, right - left, area.h, strokeStyle);
        });
    }

    protected drawAreaRangesHatchedReversed(
        canvas: CanvasRenderingContext2D,
        area: Area,
        graph: Dygraph,
        ranges: GraphRange[],
        strokeStyle: string
    ): void {
        ranges.forEach(slot => {
            const left = graph.toDomXCoord(slot.start);
            const right = graph.toDomXCoord(slot.end);
            this.drawHatchedReversed(canvas, left, area.y, right - left, area.h, strokeStyle);
        });
    }

    protected drawHatched(canvas: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, color: string) {
        canvas.beginPath();
        const right = x + w;
        for (let _x = x + 5; _x < right + (h); _x += AbstractHistoryGraph.HATCHED_SIZE) {
            if (_x < right) {
                canvas.moveTo(_x, y);
                canvas.lineTo(x, y + _x - x);
            } else {
                canvas.moveTo(right, y + _x - right);
                canvas.lineTo(x, y + _x - x);
            }
        }
        canvas.strokeStyle = color;
        canvas.stroke();
    }

    protected drawHatchedReversed(canvas: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, color: string) {
        canvas.beginPath();

        const right = x + w;

        for (let _x = x - h; _x < right; _x += AbstractHistoryGraph.HATCHED_SIZE) {
            if (_x === x) {
                // Diesen Strich nicht doppelt zeichnen
            } else if (_x >= x) {
                canvas.moveTo(_x, y);
                canvas.lineTo(right, y - _x + right);
            } else {
                canvas.moveTo(x, y + _x - x + h);
                canvas.lineTo(right, y + _x - x + right - x + h);
            }
        }

        canvas.strokeStyle = color;
        canvas.stroke();
    }

    public ngOnDestroy(): void {
        clearTimeout(this.reloadTimeout);
        clearTimeout(this.pollTimeout);
    }

    protected convertPowerUnits(val: number, numPhases: number, sourceUnit: PowerUnits, targetUnit: PowerUnits): number {
        const isInAmps = sourceUnit === PowerUnits.A;

        if (isInAmps && targetUnit === PowerUnits.A) {
            return val;
        } else {
            if (targetUnit === PowerUnits.W) {
               return isInAmps ? ampsToW(val, numPhases) : val;
            } else {
                return isInAmps ? val : wToAmps(val, numPhases);
            }
        }

        function ampsToW(val: number, phase: number): number {
            return val * AbstractHistoryGraph.VOLTAGE * phase;
        }

        function wToAmps(val: number, phase: number): number {
            return val / (AbstractHistoryGraph.VOLTAGE * phase);
        }
    }

    protected sumGraphLines(...data: Array<HistoryDataElement>[]): Array<HistoryDataElement> {
        // Remove empty lines, they will break do..while loop
        for(let i = 0; i < data.length; i++) {
            if(data[i].length === 0){
                data.splice(i, 1);
                i--;
            }
        }
        // Stop if there are no values at all
        if (data.length === 0) {
            return [];
        }

        // Setup data, it has to be sorted
        data.forEach(col => col.sort((a, b) => a.time - b.time));
        const counter: number[] = [];
        for (const item of data) {
            counter.push(0);
        }

        const result: Array<HistoryDataElement> = [];

        let next: number;
        let minTst: number;
        let val: number;
        let min: number;
        let max: number;
        do {
            val = 0;
            min = 0;
            max = 0;
            next = -1;
            minTst = Number.MAX_SAFE_INTEGER;

            for(let i = 0; i < data.length; i++){
                if (data[i].length > counter[i]) {
                    if (data[i][counter[i]].time < minTst) {
                        minTst = data[i][counter[i]].time;
                        next = i;
                    }
                    val += data[i][counter[i]].val;
                    min += data[i][counter[i]].min;
                    max += data[i][counter[i]].max;
                } else {
                    val += data[i][counter[i]-1].val;
                    min += data[i][counter[i]-1].min;
                    max += data[i][counter[i]-1].max;
                }
            }

            if(next !== -1) {
                counter[next]++;
                result.push({
                    time: minTst,
                    val,
                    min,
                    max
                })
            }
        } while (next !== -1)
        return result;
    }
}
